<template>
    <div>
        <div v-for="member in filteredMembers" :key="member.id">
            <card class="card">
                <div class="mycard">
                    <div class="row head">
                        <div class="col col-lg-2 col-2" > <img :src="member.picture" class="userPicture"> </div>
                        <div class="col col-lg-3 col-md-4 col-sm-4 col-10 email">{{member.email}}</div><div class="col col-lg-2 col-4">{{member.lastName}}</div>
                        <div class="col col-lg-2 col-4">{{member.firstName}}</div>
                        <div class="col col-lg-3 col-4"> {{member.role}} </div>
                    </div>
                </div>
            </card>
        </div>
        <div v-if="teamMembers.length" v-observe-visibility="handleScrolledToBottom"> 
        <scroll-loader v-if="!allMembersLoaded" :loader-method="handleScrolledToBottom"></scroll-loader>
        </div>
    </div>
</template>
<script>
export default {
     data: function() {
            return {  
            }
    },
    computed:{
        teamMembers(){
        return this.$store.getters['contractor/getTeamMembers2'];
        },
        filteredMembers() {
        return this.$store.getters['contractor/getfilteredMembers'];
        },
    },
    methods:{
        handleScrolledToBottom(isVisible)
        {
            if(!isVisible){ return }      
            let options = {
            limit: 20,
            start: this.teamMembers.length
            }  
            this.$store.dispatch('contractor/loadMoreMembers');     
        },
        allMembersLoaded()
        {
        return this.$store.getters['contractor/teamLoaded']
        }
    }
}
</script>
<style scoped>
.email{
    font-weight: bold
}
.userPicture{
    border-radius: 15px;
    width: 40px;
    height: 40px;
}
.col{
    color:#2d3748;
    text-align: center;
    padding: 0px 5px 0px 5px;
}
.head{
    font-size: 14px;
}
.card:hover{
    background-color: #8f919417;
}
.card{
    border-radius: 10px;
}
@media screen and(max-width:440px){
   .head{
    font-size: 13px;
} 
.userPicture{
    width: 20px;
    height: 20px;
}
}
</style>
