import axios from 'axios'
import configColor from './config'
import AuthService from '@/auth/AuthService'

/**variables**/
let apiURL = process.env.VUE_APP_API_URI
let accountId;
let config; 
let httpClient = axios.create({
    baseURL: apiURL
})
let refreshConfig=function(){
    accountId= AuthService.getUserTokenId();
    config = {
      headers: { 'Content-Type': 'application/json','Authorization':`Bearer ${accountId}` }
    };
} 
let refreshConfigFile=function(){
  accountId= AuthService.getUserTokenId();
  config = {
    headers: { 
      'Content-Type': 'multipart/form-data','Authorization':`Bearer ${accountId}`
    }
  };
} 
let refreshConfigDownload=function(){
  accountId= AuthService.getUserTokenId();
  config = {
    headers: { 'Content-Type': 'application/json','Authorization':`Bearer ${accountId}` },
    responseType: 'blob'

  };
} 
export default {
//////////////////////// ACCOUNT MANAGEMENT /////////////////////////////////////
    createAccount: async(payload) => {
      try{
        refreshConfig();

        let result = await httpClient.post('/public/accounts/signup ',
        { 
          'email': payload.email,
          'password':payload.password,
        },config)  
          let account = result.data
          return account
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
//////////////////////// PROFILE MANAGEMENT /////////////////////////////////////
    getProfile: async() => {           
          try{
            refreshConfig();
            let result = await httpClient.get('/accounts/me',config)        
            let profile = result.data
            return profile
          }
          catch (e) {
            console.log(e)
            throw e
          }
    },
    setProfile: async(payload) => {
      try{
        refreshConfig();

        let result = await httpClient.put('/accounts/me',
        { 
          'firstName': payload.firstName,
          'lastName':payload.lastName,
          'phoneNumber':payload.phoneNumber
        },config)  
          let profile = result.data
          return profile
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    //////////////////////////// OPERATION MANAGEMENT ///////////////////////////////
    getOperationById: async (operationId) => {
      try{
        refreshConfig();
        let result = await httpClient.get('/operations/' + operationId, config)
        let operation = result.data
        return operation
      }
      catch (e) {
        throw e
      }
    },
    getOperations: async(options) => {
      try{
        refreshConfig();
        let result;
        var statusArraytemp=[];
        statusArraytemp.push(options.status)
        var statusArray;
          if(options.status){statusArray=statusArraytemp[0]}
          if(statusArray && options.lastName && options.firstName){
            result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)        
          }
          else {
            result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category,config)        
          }
        /*else if(options.lastName && options.firstName){
          result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+"&category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)   
        }
        else{
          result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+"&category_contains="+options.category,config)   
        }*/
        let operations = result.data
        return operations
      }
      catch (e) {
      console.log(e)
      throw e
      }
      },
      searchOperation: async(keyword) => {
        let result = await httpClient.get('/operations?_q='+ keyword+'&_sort=createdAt:DESC', config)
        return result.data
      },
      setOperation: async(payload) => {
        try{
          refreshConfig();
          let result = await httpClient.post('/operations',
          { 
            'description': payload.description,
            'priority':payload.priority,
            'status':payload.status,
            'title':payload.title,
            'category':payload.category,
            'contact': payload.contact,
            'location': payload.location,
            'secondaryContact': payload.secondaryContact

          },config)  
            let operation = result.data
            return operation
        }
        catch (e) {
          console.log(e)
          throw e
        }
      },
      deleteOperation: async(payload) => {
        try{
          refreshConfig();
          let result = await httpClient.delete('/operations/'+payload.id,config)  
          let operation = result.data
          return operation
        }
        catch (e) {
          console.log(e)
          throw e
        }
      },
      updateOperation: async(payload) => {
        try{
          refreshConfig();
          let result = await httpClient.put('/operations/'+payload.id,
          { 
            'description': payload.description,
            'priority':payload.priority,
            'status':payload.status,
            'title':payload.title,
            'category':payload.category,
            'contact': payload.contact,
            'location': payload.location,
            'secondaryContact': payload.secondaryContact

          },config)  
            let operation = result.data
            return operation
        }
        catch (e) {
          console.log(e)
          throw e
        }
      },
    ////////////////////////////// STATUS MANAGEMENT //////////////////////////////
    changeStatus: async(payload) => 
    {
      try{
        refreshConfig();

        let result = await httpClient.put('/operations/'+payload.operationId,
        { 
          'status': payload.status,
          'description':payload.description,          
        },config)  
          let operation = result.data
          return operation
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    getAllCount: async() =>
    {
      let result;
      var statusCountArray=new Array();
      try{
        refreshConfig();
        for(var i=0;i<configColor.Status.length;i++){
        result = await httpClient.get('/operations/count?status='+configColor.Status[i].name,config)
        statusCountArray.push(result.data)
        }
        return statusCountArray
      }
      catch(e){
        console.log(e)
        throw e

      }
    },
    //////////////////////////////////////LANDING MANAGEMENT////////////////////////////
    getLandingbyId: async(landingId) =>
    {
      if(landingId){
      try{
        refreshConfig();
        let result = await httpClient.get('/landings/'+landingId, config)
        let landing = result.data
        return landing
      }
      catch (e) {
        console.log(e)
        throw e
      }
    }
    },
    updateLanding: async(payload,landingId) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/landings/'+landingId,
        { 
          'address': payload.address,
          'phone':payload.phone,
          'description':payload.description,
          'domain':payload.domain,
          'title':payload.title,
          'logo':payload.logo
        },config)  
          let landing = result.data
          return landing
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    //////////////////////////////////// TEAM MANAGEMENT //////////////////////////////////////////////
    getTeamMembers: async() =>
    {
      try{
        refreshConfig();
        let result1 = await httpClient.get('/accounts/me',config)        
        let profile = result1.data
        let result = await httpClient.get('/organizations/'+profile.organization.id,config)  
        let members = result.data
          return members
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    setNewMember: async(payload) =>{
      try{
        refreshConfig();
        let result = await httpClient.post('/accounts/newmember/',
        { 
          'email': payload.email,
          'firstName':payload.firstName,
          'lastName':payload.lastName,
          'password':payload.password,
        },config)  
          let member = result.data
          return member
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    setAssignee: async(payload) => 
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/operations/'+payload.operationId,
        { 
          'assignee': payload.assignee,          
        },config)  
          let assignee = result.data
          return assignee
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
        //////////////////////////////////// CLIENT MANAGEMENT //////////////////////////////////////////////
    getClients: async() => 
    {
      try{
        refreshConfig();
        let result = await httpClient.get('/clients',config)  
        let clients = result.data
        return clients
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
        //////////////////////////////////// EVENTS MANAGEMENT //////////////////////////////////////////////
    setOperationEvent: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.post('/events/',
        { 
          "operation" : payload.id,
          "startDate" : payload.startDate,
          "endDate" : payload.endDate,
          "title" : payload.title,
          "summary" : payload.title,
          "assignee": payload.assignee,          
        },config)  
          let event = result.data
          return event
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    getEventbyOperation: async(operationId) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.get('/events?_sort=startDate:DESC&operation='+operationId,config)  
        let event = result.data
        return event
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    getAllEvents: async(payload) =>
    {
      let options=payload.options
      let result;
      try{
        refreshConfig();
        if(options.lastName && options.firstName){
          result = await httpClient.get('/events?_sort=startDate:DESC&operation.organization='+payload.id+"&operation.priority_contains="+options.priority+"&operation.category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)        
        }
        else {
          result = await httpClient.get('/events?_sort=startDate:DESC&operation.organization='+payload.id+"&operation.priority_contains="+options.priority+"&operation.category_contains="+options.category,config)        
        }
        let events = result.data
        return events
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    getEventbyAssignee: async(assigneeId) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.get('/events?assignee='+assigneeId,config)  
        let event = result.data
        return event
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    deleteOperationEvent: async(event) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.delete('/events/'+event.id,config)  
          let event1 = result.data
          return event1
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    editOperationEvent: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/events/'+payload.idEvent,
        { 
          "operation" : payload.id,
          "startDate" : payload.startDate,
          "endDate" : payload.endDate,
          "title" : payload.title,
          "summary" : payload.title,
          "assignee": payload.assignee,           
        },config)  
          let event = result.data
          return event
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    //////////////////////// UPLOAD MANAGEMENT //////////////////////////
    setOperationAttachments: async(payload) =>
    {
      var formData = new FormData()
        /*
        let jsondata = {};
        jsondata.title = payload.title;
        jsondata.operation= payload.id;
        */
        formData.append('data','{"title":"'+payload.title+'","operation":"'+payload.id+'"}')
        // formData.append('data', JSON.stringify(jsondata))
        let filesArray = []
        for (const file of payload.media) {
            //filesArray.push(file.blob)
            formData.append('files.media',file.raw)
        }
        //formData.append('files.media',filesArray)
        
      try{
        refreshConfigFile();
        let result = await httpClient.post('/attachments',formData,config)  
        let files = result.data
        return files
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    setOperationNotes: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.post('/notes',
        { 
          "operation" : payload.id,
          "title" : payload.title,
          "content" : payload.description,         
        },config)  
          let event = result.data
          return event
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    editOperationNotes: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/notes/'+payload.idNote,
        { 
          "operation" : payload.id,
          "title" : payload.title,
          "content" : payload.description,         
        },config)  
          let event = result.data
          return event
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    setPriority: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.post('/priorities',
        { 
          "configuration" : payload.id,
          "name" : payload.name,
          "label" : payload.label,   
          "color":payload.color,
          "ranking":payload.ranking   
        },config)  
          let priority = result.data
          return priority
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    getConfiguration: async(configId) =>
    {
     /* let result1 = await httpClient.get('/accounts/me',config)        
      let profile = result1.data
      let result = await httpClient.get('/organizations/'+profile.organization.id,config)  
      let organization = result.data*/
      if(configId){
        try{
          refreshConfig();
          let result = await httpClient.get('/configurations/'+configId,config)  
          let configuration = result.data
          return configuration
        }
        catch (e) {
          console.log(e)
          throw e
        }
      }
      
    },
    deletePriority: async(priority) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.delete('/priorities/'+priority.id,config)  
        let priorityres = result.data
        return priorityres
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    editPriority: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/priorities/'+payload.idPriority,
        { 
          "configuration" : payload.id,
          "name" : payload.name,
          "label" : payload.label,   
          "color":payload.color,
          "ranking":payload.ranking          
        },config)  
          let priority = result.data
          return priority
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    deleteStatus: async(status) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.delete('/statuses/'+status.id,config)  
        let statusres = result.data
        return statusres
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    editStatus: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/statuses/'+payload.idStatus,
        { 
          "configuration" : payload.id,
          "name" : payload.name,
          "label" : payload.label,   
          "color":payload.color,
          "ranking":payload.ranking          
        },config)  
          let status = result.data
          return status
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    setStatus: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.post('/statuses',
        { 
          "configuration" : payload.id,
          "name" : payload.name,
          "label" : payload.label,   
          "color":payload.color,
          "ranking":payload.ranking   
        },config)  
          let status = result.data
          return status
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    deleteCategory: async(category) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.delete('/categories/'+category.id,config)  
        let categoryres = result.data
        return categoryres
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    editCategory: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/categories/'+payload.idStatus,
        { 
          "configuration" : payload.id,
          "name" : payload.name,
          "ranking":payload.ranking          
        },config)  
          let category = result.data
          return category
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    setCategory: async(payload) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.post('/categories',
        { 
          "configuration" : payload.id,
          "name" : payload.name,
          "ranking":payload.ranking   
        },config)  
          let category = result.data
          return category
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    addMap: async(operations) =>
    {
      
      
      let mapCoordinates=new Array();
      let result;
      try{
        refreshConfig();
        for(var i=0;i<operations.length;i++){
          
          if(operations[i].location!=null && operations[i].location!=undefined){
            result = await axios.get('https://nominatim.openstreetmap.org/search?format=json&street='+operations[i].location.address+'&country='+operations[i].location.country+'&postalcode='+operations[i].location.zipCode+'&city='+operations[i].location.city+'&addressdetails=1')  
            if(result.data[0]!=undefined){
            let objectCoord=new Object();
            objectCoord.lat=result.data[0].lat;
            objectCoord.lon=result.data[0].lon;
            objectCoord.title=operations[i].title;
            objectCoord.operationId=operations[i].id;
            objectCoord.id=i;
            mapCoordinates.push(objectCoord)
            }         
        }  
        }
        //console.log(mapCoordinates)

        return mapCoordinates;
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    updatePassword: async(password) =>
    {
      try{
        refreshConfig();
        let result = await httpClient.put('/accounts/changePassword',
        { 
          "newPassword" : password 
        },config)  
          let password1 = result.data
          return password1
      }
      catch (e) {
        console.log(e)
        throw e
      }
    },
    downloadAllOperations: async() =>
    {
      try{
        refreshConfig();
        let result = await httpClient.get('/download/operations?_sort=createdAt:DESC&_limit=1000',config)
        // Adding the string "\ufeff" to the Blob is improtant to have special characters like (é, à) displayed correctly in Excel 
        let url = window.URL.createObjectURL(new Blob(["\ufeff",result.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
      }
      catch(e){
        console.log(e)
      }
    }
}