<template>
    <modal name="confirm-modal" class="eventModal" :delay='100' height="auto" :resizable='true' :adaptive="true" :clickToClose='false' >
        <card class="card" >  
            <div class="row">
                <div class="col-12 title text-center"><p>Etes vous sûr de vouloir supprimer?</p></div>   
                <div class="col-6 text-center"><el-button v-on:click='confirm(false)' type="primary">Annuler</el-button></div><div class="col-6 text-center"><el-button v-on:click='confirm(true)' type="danger">Supprimer</el-button></div>
            </div>
        </card>
    </modal>
</template>
<script>
import EventBus from '@/eventBus'
export default {
    data: function() {
            return {  
            submitted: false,
            deleted:false,
            opened:false,
            event:{}
            }
    },
    methods:{
        show () {
            this.$modal.show('confirm-modal');
        },
        hide () {
            this.$modal.hide('confirm-modal');
        },
        confirm(value){
            let payload={
                opened:this.opened,
                deleted:value
            }
            EventBus.$emit('DELETE', payload);
            this.hide()
        }
    },
    mounted() {
        this.$modal.show('confirm-modal');
    },
    created(){
        let that=this;
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.hide();
            }
        });  
    }
}
</script>
<style scoped>
.title{
    color: rgb(119, 111, 111);
    margin-top: 40px;
}
.vm--container::v-deep .vm--modal{
    left: 500px !important;
    border-radius: 7px !important;
}
@media screen and (max-width: 1100px) {
  .vm--container::v-deep .vm--modal{
    left: 0px !important;   
    text-align: center !important;
    padding: 0 !important;
    margin-top:100%
}
.vm--container::v-deep .vm--overlay{
    margin-top: 100%;
}
}
</style>
