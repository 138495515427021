<template>
<div class="col-12">
    <card>     
        <b-card-header>
            <h5>Ajouter une nouvelle opération</h5>
            <h6 class="obligatoireTitle"><span class="obligatoire">*</span> champs obligatoires</h6>
        </b-card-header>
            <form @submit.prevent="add()">    
                     
                <div class="row">
                    <div class="col-md-12">
                        <label >Titre </label><span class="obligatoire"> *</span>
                        <input type="text"
                        placeholder="Titre"
                        v-model="newOperation.title"
                        name="titre"
                        class="form-control" required/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Desciption</label><span class="obligatoire"> *</span>
                        <textarea type="text" placeholder="Description" v-model="newOperation.description" name="desciption" class="form-control" rows=3 required/> 
                    </div>          
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Catégorie </label><span class="obligatoire"> *</span>
                        <select class="form-control custom-select" v-model="newOperation.category" required>
                            <option v-for="c in config.Categories" :key="c.id" selected>{{c.name}}</option>
                        </select>
                    </div>        
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Priorité </label><span class="obligatoire"> *</span>
                        <select class="form-control custom-select" v-model="newOperation.priority" required>
                            <option v-for="priorité in config.Priorities" :key="priorité.id" selected>{{priorité.name}}</option>
                        </select>
                    </div>        
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="form-title">Contact principal</h5>
                    </div>
                </div>
                <div class="row" v-if="newOperation.contact">
                    <div class="col-md-4">
                        <label>Nom du contact </label><span class="obligatoire"> *</span>
                        <input type="text"
                        placeholder=""
                        v-model="newOperation.contact.name"
                        name="name"
                        class="form-control" />
                    </div>  
                    <div class="col-md-4">
                        <label>Téléphone</label><span class="obligatoire"> *</span>
                        <input type="tel"
                        placeholder=""
                        v-model="newOperation.contact.phoneNumber"
                        name="phoneNumber"
                        @input="acceptNumber"
                        class="form-control" />
                    </div> 
                    <div class="col-md-4">
                        <label>Email </label><span class="obligatoire"> *</span>
                        <input type="email"
                        placeholder=""
                        v-model="newOperation.contact.email"
                        name="email"
                        class="form-control" />
                    </div>      
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="form-title">Contact secondaire</h5>
                    </div>
                </div>
                <div class="row" v-if="newOperation.secondaryContact">
                    <div class="col-md-4">
                        <label>Nom du contact</label>
                        <input type="text"
                        placeholder=""
                        v-model="newOperation.secondaryContact.name"
                        name="name"
                        class="form-control" />
                    </div>  
                    <div class="col-md-4">
                        <label>Téléphone</label>
                        <input type="tel"
                        placeholder=""
                        v-model="newOperation.secondaryContact.phoneNumber"
                        name="phoneNumber"
                        @input="acceptNumber"
                        class="form-control" />
                    </div> 
                    <div class="col-md-4">
                        <label>Email</label>
                        <input type="email"
                        placeholder=""
                        v-model="newOperation.secondaryContact.email"
                        name="email"
                        class="form-control" />
                    </div>      
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="form-title">Adresse</h5>
                    </div>
                </div>
                <div class="row" v-if="newOperation.location">
                    <div class="col-md-12">
                        <label>Adresse</label><span class="obligatoire"> *</span>
                        <input type="text"
                        placeholder=""
                        v-model="newOperation.location.address"
                        name="address"
                        class="form-control" required/>
                    </div>     
                </div>
                <div class="row" v-if="newOperation.location">
                    <div class="col-md-4">
                        <label>Ville</label><span class="obligatoire"> *</span>
                        <input type="text"
                        placeholder=""
                        v-model="newOperation.location.city"
                        name="city"
                        class="form-control" required/>
                    </div>  
                    <div class="col-md-4">
                        <label>Code postal</label><span class="obligatoire"> *</span>
                        <input type="text"
                        placeholder=""
                        v-model="newOperation.location.zipCode"
                        name="zipCode"
                        class="form-control" required/>
                    </div> 
                    <div class="col-md-4">
                        <label>Pays</label><span class="obligatoire">*</span>
                        <input type="text"
                        placeholder=""
                        v-model="newOperation.location.country"
                        name="country"
                        class="form-control" required/>
                    </div>      
                </div>
                <div class="text-center">             
                    <p-button type="primary" :round="true" :loading='submitted'>Ajouter</p-button>      
                    <!--
                    <button type="submit" class="btn btn-round btn-primary">Update</button>
                    -->
                    <p v-if="displayAddMessage" class="update-msg">Votre opération a été ajouté avec succès</p>
                </div>
            </form>
    </card>
    </div>
</template>
<script>
import configuration from '@/api/config.js'

export default {
    data: function() {
            return {
            submitted:false,
            newOperation: {
                contact: {},
                secondaryContact: {},
                location: {}
            },
            statusFilter: ['Nouveau','Prête',"Préparation","En cours","Planifiée"],
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        
        }
        }, 
    methods:
    {
        loadOperations(){
            let options = {
            limit: 20,
            start: 0,
            status:this.statusFilter,
            category:'',
        }   
            this.$store.dispatch('contractor/loadOperations',options);
            this.$store.dispatch('contractor/setStatusCount')
        },
        async add()
        {   
            if(!this.reg.test(this.newOperation.contact.email)  && !this.reg.test(this.newOperation.secondaryContact.email))
            {
            }
            this.submitted=true;
            await this.$store.dispatch('contractor/createOperation',this.newOperation)
            this.submitted=false;
            this.loadOperations();
            this.$router.push("/dashboard/operations")
            this.$store.dispatch('contractor/loadMap');
        },
        acceptNumber() {
        var x = this.newOperation.contact.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        var y = this.newOperation.secondaryContact.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.newOperation.contact.phoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        this.newOperation.secondaryContact.phoneNumber = !y[2] ? y[1] : '(' + y[1] + ') ' + y[2] + (y[3] ? '-' + y[3] : '');
        },
    },
    computed:
    {
        /*
        newOperation: function(){
            return this.$store.getters['contractor/getNewOperation']
        },
        */
        displayAddMessage: function () {
            return this.$store.getters['contractor/displayAddMessage']
        },
        config:function(){
            return configuration
        }
    }  
    
}
</script>
<style scoped>
.obligatoireTitle{
    float: right;
}
.obligatoire{
    color: red;
}
.form-title{
    margin-top: 10px;
    margin-bottom: 10px;
}
.update-msg{
      font-size: 12px;
      color: green;
      margin-top: 15px;
}
.custom-select:focus{
    box-shadow: 0px 0px 2px #2d3748;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222; 
    outline: none;
}
.custom-select option
{   
    color: white;
    background-color: #2d3748   
}
/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.container{
  padding: 30px;  
  background-color: #f1f3f4;
  padding-bottom: 500px;
  align-content: center
}
img{
    margin-top: -120px;
    margin-bottom: 20px;   
    padding: 10px; 
}   
.form{
    text-align: center;
    padding: 10px;
    margin:auto;
} 
.col-md-12
{
    padding-bottom: 15px;
}
input,textarea
{
    background-color: #f1f3f4; 
    outline: none;
}
input:focus,textarea:focus
{
    outline: none !important;
    box-shadow: 0px 0px 2px #2d3748;
}
.form-control:focus {
    outline: none !important;
    background-color: #f1f3f4;

}
.form-control{
    background-color: #f1f3f4;
    border: none;
}
label
{
    padding-left: 12px;
    float: left;
}
</style>
