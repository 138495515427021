import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from './getters'

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    displayAuthFailureMessage: false,
    displayAuthSuccessMessage:false,
    displayRegisFailureMessage:false,
    displayRegisSuccessMessage:false,
    passwordFailureMessage:false,
    isRegistered:false,
    profileLoaded:false,
    account: {},
    profile:{},

  },
  actions,
  mutations,
  getters
}
