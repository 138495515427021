<template>
<div class="col-12">
    <div class="card">
        <div class="stats-container">
            <div class="stats-element" v-for="stat in getStatuses" :key="stat.ranking"><span v-bind:style="statusBgColor(stat.name)">{{getStatusCount[stat.ranking]}}</span><p v-on:click="goToOperations(stat.name)">{{stat.name}}</p></div>
        </div>
    </div>
</div>
</template>
<script>
import configColor from '@/api/config.js'
export default {
    computed:{
        getStatuses(){
        return configColor.Status    
        },
        getStatusCount(){
        return this.$store.getters['contractor/getStatusCount'];
        }
    },
    methods:{
        statusBgColor(status)
        {
            for(var i=0;i<configColor.Status.length;i++){
                if(configColor.Status[i].name==status)
                {
                    return { 
                    'background-color':configColor.Status[i].color,
                    };
                }
            }            
        },
        async goToOperations(status){
            let options = {
            limit: 20,
            start: 0,
            status:[status],
            category:'',
            firstName:'',
            lastName:''
            }   
            await this.$store.dispatch('contractor/loadOperations',options); 
            this.$router.push('/dashboard/operations/');
        }
    },
    created()
    {
    this.$store.dispatch('contractor/setStatusCount')
    }
}
</script>
<style scoped>
.card{
    margin-top: 20px;
    border-radius: 10px;
    font-size: 14px;
    border: none;
    padding:5px;
}
span{
    color: #ffffff;
    border-radius: 100px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.stats-container {
    display: flex;
    width:95%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-between;
}
.stats-element{
    width: 150px;
    display: flex;
    align-items: center;
    padding:8px;
}

.stats-element p{
    margin: 0;
    cursor: pointer;
}
</style>
