<template>
    <div>
        <card>     
        <b-card-header>
            <h5>Personnaliser votre configuration</h5>
        </b-card-header>
        <div class="col-12">
            <el-tabs content-class="mt-3" v-model="activeTab">
                <el-tab-pane label="Catégories" name="categories" active>
                    <div class="row headers">
                        <div class="col-lg-6 col-md-6 col-6"><p>Label</p></div><div class="col-lg-4 col-md-4 col-3"><p>Rang</p></div>
                        <div class="col-lg-2 col-md-2 col-3"><el-button type="primary" class="ti-plus" circle v-on:click="switchOnCategory()"></el-button></div>
                    </div>
                    <category></category>
                    <popup-category v-if="add3" :edit3='edit3' :add3='add3'> </popup-category>
                </el-tab-pane>
                <el-tab-pane label="Priorités" name='priorities'>
                    <div class="row headers">
                        <div class="col-lg-4 col-md-4 col-4"><p>Label</p></div><div class="col-lg-4 col-md-4 col-3"><p>Couleur</p></div><div class="col-lg-2 col-md-2 col-3"><p>Rang</p></div>
                        <div class="col-lg-2 col-md-2 col-2"><el-button type="primary" class="ti-plus" circle v-on:click="switchOnAdd()"></el-button></div>  
                    </div>
                    <priority></priority>
                    <popup-priority v-if="add1" :edit1='edit1' :add1='add1'> </popup-priority>
                </el-tab-pane>
                <el-tab-pane label="Status" name='statuses'>
                    <div class="row headers">
                        <div class="col-lg-4 col-md-4 col-4"><p>Label</p></div><div class="col-lg-4 col-md-4 col-3"><p>Couleur</p></div><div class="col-lg-2 col-md-2 col-3"><p>Rang</p></div>
                        <div class="col-lg-2 col-md-2 col-2"><el-button type="primary" class="ti-plus" circle v-on:click="switchOnAddStatus()"></el-button></div>
                    </div>
                    <status></status>
                    <popup-status v-if="add2" :edit2='edit2' :add2='add2'> </popup-status>
                </el-tab-pane>
            </el-tabs>
        </div>
    </card>
    </div>
</template>
<script>
import config from '@/api/config.js'
import category from './category.vue'
import priority from './priority.vue'
import status from './status.vue'
import popupPriority from '@/components/Popups/popupPriority'
import popupStatus from '@/components/Popups/popupStatus'
import popupCategory from '@/components/Popups/popupCategory'
import EventBus from '@/eventBus'

export default {
name:'administration',

    data: function() {
            return {
                priorityOn:false,
                edit1:false,
                add1:false,
                add2:false,
                edit2:false,
                add3:false,
                edit3:false,
                activeTab:"categories"
            }
    },
    components:{
        category,
        priority,
        status,
        popupPriority,
        popupStatus,
        popupCategory
    },
    computed:{
        configuration(){
            return config
        },
        organization() {
            return this.$store.getters['contractor/getfilteredMembers'];
        },
    },
    methods:{
    switchOnAdd(){
        this.add1=true;
    },
    switchOnAddStatus(){
        this.add2=true;
    },
    switchOnCategory(){
        this.add3=true;
    },
    },
    created(){
        EventBus.$on('ADD_PRIORITY', (addOff) => {
        this.add1=addOff;
        this.edit1=addOff
        })
        EventBus.$on('ADD_STATUS', (addOff) => {
        this.add2=addOff;
        this.edit2=addOff
        })
        EventBus.$on('ADD_CATEGORY', (addOff) => {
        this.add3=addOff;
        this.edit3=addOff
        })
    },
    mounted(){
        EventBus.$on('ADD_PRIORITY', (addOff) => {
        this.add1=addOff;
        this.edit1=addOff
        })
        EventBus.$on('ADD_STATUS', (addOff) => {
        this.add2=addOff;
        this.edit2=addOff
        })
        EventBus.$on('ADD_CATEGORY', (addOff) => {
        this.add3=addOff;
        this.edit3=addOff
        })
    }
    }
</script>
<style scoped>
.title{
    font-size: 17px !important;
}
.headers{
    padding-right: 10px;
    font-size: 16px;
    color: rgba(88, 82, 82, 0.745);
    text-align: center;
}
@media screen and (max-width:999px) {
    .headers > div{
    padding: 0px 5px 0px 5px;
}
}
</style>