<template>
        <div>
        <div v-for="operation in operations" :key="operation.id">
            <card class="card desktop-style" >
                <div class="mycard" v-on:click="goOperationDetails(operation.id)" >
                    <div class="row head">
                        <span class="col-lg-2 col-md-4 col-sm-4 col-4 operationId"><font color="#5fd5b7">No </font> {{operation.reference}}</span> <p class="priorité col-lg-1 col-md-4 col-sm-4 col-3" v-bind:style="priorityColor(operation.priority)">{{operation.priority}}</p> <div class="status col-lg-2 col-md-4 col-sm-4 col-5"><span class="h5" v-bind:style="statusBgColor(operation.status)"> <font color="#ffffff"> {{operation.status}} </font>  </span></div>
                        <p class="titreDesktop col-lg-3">{{titleSplit(operation.title)}}</p> <div class="catDesktop col-lg-1"> <p class="cat"> {{operation.category}} </p> </div>
                        <div class="userDesktop col-lg-2" v-if="(typeof(operation.assignee) == 'undefined') || (typeof(operation.assignee) == 'null')  || operation.assignee == null "> <p>  Not assigned  </p> </div>
                        <div class="userDesktop col-lg-2" v-if="(typeof(operation.assignee) != 'undefined') &&  (typeof(operation.assignee) != 'null') && operation.assignee != null "> <p> <img :src="operation.assignee.picture" class="userPicture">  {{operation.assignee.firstName}} {{operation.assignee.lastName}}  </p> </div>
                        <div class="createdat col-lg-1"> <p id="datecreation">  {{operation.createdAt.substr(0,10)}}  </p> </div>
                    </div>
                    <div class="row">
                           <div class="row">
                            <p class="titre">{{titleSplit(operation.title)}}</p>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-5"> <p class="category"> {{operation.category}} </p> </div>
                                <div class="col-md-6 col-sm-6 col-7" v-if="(typeof(operation.assignee) == 'undefined') || (typeof(operation.assignee) == 'null') || operation.assignee == null"> <p class="user"> Not assigned  </p> </div>
                                <div class="col-md-6 col-sm-6 col-7" v-if="(typeof(operation.assignee) != 'undefined') &&  (typeof(operation.assignee) != 'null') && operation.assignee != null"> <p class="user">{{operation.assignee.firstName}}  <img :src="operation.assignee.picture" class="userPicture">    </p> </div>
                            </div>
                    </div>
                </div>

            </card>
            <card class="card mobile-style" >
                <div class="mycard" v-on:click="goOperationDetails(operation.id)" >
                    <div class="row head">
                        <span class="col-6 operationId"><font color="#5fd5b7">No </font> {{operation.reference}}</span> 
                        <div class="status col-6"><span class="h5" v-bind:style="statusBgColor(operation.status)"> <font color="#ffffff"> {{operation.status}} </font>  </span></div>
                    </div>
                    <div class="row">
                            <p class="col-12 titre-mobile">{{titleSplit(operation.title)}}</p>                                <!--
                                <div class="col-md-6 col-sm-6 col-5"> <p class="category"> {{operation.category}} </p> </div>
                                -->
                                <div class="col-7 creation-date-mobile"><p>Créé le {{operation.createdAt.substr(0,10)}} </p></div>
                                <div class=" col-5" v-if="(typeof(operation.assignee) == 'undefined') || (typeof(operation.assignee) == 'null') || operation.assignee == null"> <p class="user"> Not assigned  </p> </div>
                                <div class=" col-5" v-if="(typeof(operation.assignee) != 'undefined') &&  (typeof(operation.assignee) != 'null') && operation.assignee != null"> <p class="user">{{operation.assignee.firstName}}  <img :src="operation.assignee.picture" class="userPicture">    </p> </div>
                    </div>
                </div>

            </card>
        </div>
        <div v-if="operations.length" v-observe-visibility="handleScrolledToBottom"> 
            <scroll-loader :loader-method="handleScrolledToBottom" :loader-disable="allOperationsLoaded" :loader-size='30'></scroll-loader>
        </div>
    </div>
</template>
<script>
import configColor from '@/api/config.js'
import AuthService from '@/auth/AuthService.js'
import EventBus from '@/eventBus'

export default {
    data: function() {
            return {
            search:'',
            limit:6,
            disable:false,
            value:true,
            status:[],
            category:'',
            firstName:'',
            lastName:''
            }
    },
    computed:{
        operations(){
        return this.$store.getters['contractor/getOperations'];
        },     
        filterOperations() {
        return this.$store.getters['contractor/getfilteredOperations'];
        },
        getAssigneePicture(assignee)
        {
        let user=JSON.parse(localStorage.getItem('user_account'));
        return user.picture
        }, 
        allOperationsLoaded () {
        return this.$store.getters['contractor/operationsLoaded']
        }
    },
    methods:{
        handleScrolledToBottom(isVisible)
        {
            if(!isVisible){ return }      

            this.assigneeFilter=''
            this.categoryFilter=''
            this.statusFilter=['Nouveau','Prête',"Préparation","En cours","Planifiée"]
            let options = {
            limit: 20,
            start: this.operations.length,
            status:this.statusFilter,
            category:this.categoryFilter,
            firstName:this.assigneeFilter,
            lastName:this.assigneeFilter
            }   
            EventBus.$emit('ADD_FILTER', options);
            this.$store.dispatch('contractor/loadMoreOperations',options);     
        },
        goOperationDetails(id){
            this.$store.dispatch('contractor/setOperationId',id);
            this.$router.push('/dashboard/operations/'+id);
        },
        statusBgColor(status)
        {
            for(var i=0;i<configColor.Status.length;i++){
                if(configColor.Status[i].name==status)
                {
                    return { 
                    'background-color':configColor.Status[i].color,
                    'border-color':configColor.Status[i].color,
                    'color':'white'
                    };
                }
            }            
        },
        priorityColor(priority)
        {
        for(var i=0;i<configColor.Priorities.length;i++){
            if(configColor.Priorities[i].name==priority)
            {
                return { 
                'color':configColor.Priorities[i].color,
                };
            }
        }
        },
        titleSplit(title){
            var stringTitle='';
            if(title && title.length>40)
            {
                for(var i=0;i<5;i++){
                    stringTitle=stringTitle+' '.concat(title.split(" ")[i])
                }
                return stringTitle
            }
            else return title
            
            console.log(title.length)
        }
    },
    created(){
        EventBus.$on('ADD_FILTER', (options) => {
            this.status=options.status
            this.category=options.category
            this.firstName=options.firstName
            this.lastName=options.lastName
        })
    }
}
</script>
<style scoped>
.desktop-style{
    display: block;
    height: 65px;
}
.mobile-style {
    display: none;
    box-shadow: 0 2px 2px rgb(204 201 220 / 49%);
    height: 130px;
}

.titre-mobile {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.creation-date-mobile p{
    font-size: 12px;
    color: gray;
}
.mobile-style hr {
    border-top:1px solid #f1f3f4;
    margin : 10px 0px;
}
.createdat{
    padding-right: 0px;
    color: gray;
}

.operationId {
    font-weight: 600;
}
#datecreation{
    font-size: 12px !important;
}
.userPicture{
    border-radius: 15px;
    width: 25px;
    height: 25px;
}
.searchInput{
    padding:auto;
    padding-left: 20px;
    width: 30%;
    margin: 0px;
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    float: left
}
.card:hover{
    background-color: #8f919417;
    cursor: pointer;
}
.card{
  margin-top:10px;
  border: none;
  font-size: 14px;
  border-radius: 10px;
}
hr {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
p{
    margin-bottom: 0px;
  font-size:14px; 
}
.status{
    float: left;
    font-weight: 700;
    line-height: 99%;
    text-transform: uppercase;
}
.h5{
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    padding:2px 10px;
}
.priorité{
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
}
.row{
    padding-left: 5px;
}
.user{
    float: right;
    padding: 0px;
}
.catDesktop{
    padding: 0px;
    padding-left: 10px;
    margin: auto;
}
.cat,.category{
    color: #eebb4d;
    padding: 0px;
    font-size: 13px;
}
.titre{
    padding-left: 20px;
    font-size: 14px;
}
.titreDesktop,.userDesktop,.operationId{
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
}
.userDesktop{
    padding: 0px;
    text-align: center
}
@media screen and (min-width: 999px){
   .category,.user,.titre
   {
       display: none;
   }
    .catDesktop,.titreDesktop,.userDesktop
   {
       display: block;
   }
   hr{
       display: none;
   }
}
@media screen and (max-width: 999px){
   .catDesktop,.titreDesktop,.userDesktop,.headers
   {
   display: none;
   }
   .status,.priorité{
    text-align: right;
   }
   .desktop-style{
    display: none;
    }
    .mobile-style{
    display: block;
    }
}
@media screen and (max-width: 695px){
#opcard,.priorité,.category{
    font-size: 14px
}
.desktop-style{
    display: none;
}
.mobile-style{
    display: block;
}
}
@media screen and (max-width: 576px){
.desktop-style{
    display: none;
}
.mobile-style{
    display: block;
}
.priorité{
    margin-right: 0px;
    margin-left: 0px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}
.searchInput{
    width: 50%}
.head{
    margin-right: 0px;}
}
</style>