<template>
  <div class="col-12">
        <card class="card" >
        <b-card-header>
            <h5>Personnaliser votre vitrine client</h5>
            <p>Cette section vous permet de personnaliser votre page web que vous
                pouvez partager avec vos clients pour la réception des demandes
            </p>
        </b-card-header> 
                <form @submit.prevent="update()">
                    <div class="row">
                        <div class="col-md-12">
                            <label >Nom</label>
                            <input type="text"
                            placeholder="Domaine"
                            name="domain"
                            class="form-control" 
                            v-model="landing.domain"
                            />
                        </div>
                    </div>   
                    <div class="row">
                        <div class="col-md-12">
                            <label >Votre logo</label>
                            <input type="text"
                            name="titre"
                            class="form-control"    
                            placeholder="Put your logo url"
                            v-model="landing.logo"     
                            />
                             
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label >Titre</label>
                            <input type="text"
                            placeholder="Titre"
                            name="titre"
                            class="form-control"
                            v-model="landing.title"
                             />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label >Adresse</label>
                            <input type="text"
                            placeholder="Adresse"
                            name="adresse"
                            class="form-control" 
                            v-model="landing.address"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label >Téléphone</label>
                            <input type="tel"
                            placeholder="Téléphone"
                            name="phone"
                            class="form-control" 
                            v-model="landing.phone"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label>Description de votre activité</label>
                            <vue-editor v-model="landing.description"></vue-editor>
                        </div>          
                    </div>
                    <div class="text-center">              
                        <p-button type="primary" :round="true" :loading='submitted'>METTRE A JOUR</p-button>
                    </div>                    
                </form>
        </card>
    </div>
</template>
<script>
export default {
    data: function() {
            return {  
                submitted:false,
            }
    },
    computed:{
      profile() {
        return this.$store.getters['auth/getProfile'];
      },
      landing() {
        return this.$store.getters['contractor/getLanding'];
      },
      landingUpdated()
      {
        return this.$store.getters['contractor/landingMsg'];     
      }
    },
    methods:{
        async update(){
        this.submitted=true
        let payload={
        description:this.landing.description,
        address:this.landing.address,
        phone:this.landing.phone,
        domain:this.landing.domain,
        title:this.landing.title,
        logo:this.landing.logo
        }
        await this.$store.dispatch('contractor/updateLanding',payload);
        this.submitted=false;
        }
    }
}
</script>
<style scoped>
.land-updated-success{
    color: green;
    margin-top: 25px;
    font-size: 12px;
}
img{
    margin-top: -120px;
    margin-bottom: 20px;   
    padding: 10px; 
}   
.form{
    text-align: center;
    padding: 10px;
    margin:auto;
}

input,textarea
{
    background-color: #f1f3f4; 
}
input:focus,textarea:focus
{
    outline: none;
    background-color: #f1f3f4; 
    box-shadow: 0px 0px 2px #2d3748;
}
.form-control{
    background-color: #f1f3f4;
    border: none;
}
label
{
    padding-left: 12px;
    float: left;
}
.quillWrapper {
    margin-top: 30px;
}
</style>
