import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from './getters'

export default {
  namespaced: true,
  state: {
    organizationId:null,
    configurationId:null,
    landingId:null,
    operationsLoaded:false,
    operations: {},
    filteredOperations:{},
    operationId:null,
    newOperation:{
      contact : {},
      location : {},
      secondaryContact: {}
    },
    landing:{},
    landingUpdated:false,
    currentOperation:{},
    operationAdded:false,
    statusChanged:false,
    statusCounts:[],
    teamMembers: [],
    teamMembers2:[],
    filteredMembers: {},
    operationAssigned:false,
    teamLoaded:false,
    clients: {},
    filteredClients:{},
    newEvent:{},
    currentEvent:{},
    assigneeEvent:{},
    allEvents:{},
    ElementPriority:{},
    ElementStatus:{},
    ElementCategory:{},
    eventUploaded:false,
    media:[],
    notes:[],
    note:{},
    categories:[],
    priorities:[],
    statuses:[],
    filteredassignedOperations:{},
    mapCoords:{}
  },
  actions,
  mutations,
  getters
}