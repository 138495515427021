<template>
    <div>
        <div v-for="status in statuses" :key="status.id">
            <card class="mycard">
                <div class="row rowContainer">
                    <div class="col-lg-4 col-md-4 col-4">{{status.name}}</div>
                    <div class="col-lg-4 col-md-4 col-3" v-bind:style="'background-color:'+status.color"><span></span></div>
                    <div class="col-lg-2 col-md-2 col-2">{{status.ranking}} </div>
                    <div class="col-lg-2 col-md-2 col-3 editIcons">
                        <a class="button"  @click="deleteStatus(status)">
                            <h6 class="fa fa-close icon"></h6> 
                        </a>
                        <a class="button"  @click="edit(status)">
                            <h6 class="fa fa-edit icon"></h6> 
                        </a>   
                    </div>
                </div>
            </card>
        </div>
        <popup-status v-if="edit2" :edit2='edit2' :add2='add2'> </popup-status>
        <popup-confirmation v-if="confirmation"></popup-confirmation>
    </div>
</template>

<script>
import config from '@/api/config.js'
import popupStatus from '@/components/Popups/popupStatus'
import EventBus from '@/eventBus'
import popupConfirmation from '@/components/Popups/popupConfirmation.vue'

export default {
 data(){
        return {
            edit2:false,
            add2:false,
            confirmation:false
        }
},
components:{
    popupStatus,
    popupConfirmation
},
computed:{
    configuration(){
        return config
    },
    organization() {
        return this.$store.getters['contractor/getfilteredMembers'];
    },
    statuses(){
        return this.$store.getters['contractor/getStatuses'];
    },
},
methods:{
    edit(status){
        this.edit2=true
        this.$store.dispatch('contractor/getStatusElement',status)
    },
    deleteStatus(status){
        this.confirmation=true;
        let vm=this;
        EventBus.$on('DELETE', (payload) => {
        this.confirmation=payload.opened
        if(payload.deleted){
            this.$store.dispatch('contractor/deleteStatus',status)
            setTimeout(function(){vm.$store.dispatch('contractor/loadConfig')}, 500)
        }
        this.confirmation=false;
        EventBus.$off('DELETE')
        })
    },
},
created(){
    EventBus.$on('ADD_STATUS', (addOff) => {
        this.add2=addOff;
        this.edit2=addOff
        })
}
}
</script>
<style scoped>
.mycard:hover{
    background-color: #8f919417;
    cursor: pointer;
}
.rowContainer > div{
    color: #2d3748;
    text-align: center;
}
.fa-close{
    color: red !important;
}
.icon{
    visibility: hidden;
    float: right;
    color: #2d3748;
    font-weight: bold;
}
@media screen and (max-width:999px) {
    .icon{
        visibility: visible;
    }
    .rowContainer > div{
    padding: 0px 5px 0px 5px;
}
}
</style>
<style lang="scss" scoped>
.mycard:hover .icon{
    visibility: visible;
    cursor: pointer;
}
</style>