<template>
  <div class="callback">
    <h1 class="headlogo">Opti<span class="logop">mop</span></h1>  
    <span class="loading"> Loading ... </span>
    </div>
</template>

<script>
export default {
  name: 'callback',
    created () {
    this.$store.dispatch('auth/handleAuthCallback')
    } ,
    data () {
      return {}
    }
}
</script>
<style >
.logop{
    color: #eebb4d;
    font-weight: bold;
}
.headlogo{
    padding-top:20px;
    font-weight: bold;   
}
.loading
{
    font-size: x-large;
    padding-top:400px;
    font-weight: bold; 
}
.callback
{
  text-align: center
}
</style>
