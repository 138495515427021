<template>
    <div class="col-12">
    <card>
         <div class="card-header">
            <h5>Ajouter un nouveau membre d'équipe</h5>
        </div>  
            <form @submit.prevent="add()">
                <div class="row">
                    <div class="col-md-12">
                        <label >Adresse mail</label>
                        <input type="text"
                        placeholder="Email"
                        v-model="newMember.email"
                        name="titre"
                        class="form-control" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label >Prénom</label>
                        <input type="text"
                        placeholder="First name"
                        v-model="newMember.firstName"
                        name="titre"
                        class="form-control" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label >Nom</label>
                        <input type="text"
                        placeholder="Last name"
                        v-model="newMember.lastName"
                        name="titre"
                        class="form-control" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label >Mot de passe</label>
                        <input type="text"
                        placeholder="Password"
                        v-model="newMember.password"
                        name="titre"
                        class="form-control" />
                    </div>
                </div>
                <div class="text-center">              
                    <p-button type="primary" :round="true" :loading='submitted'>Ajouter</p-button>
                    <p v-if="displayerrorPasswordMessage" class="error-msg">Votre mot de passe est trop court, veuillez réessayer!</p>
                </div>
            </form>
    </card>
    </div>
</template>
<script>
export default {
    data: function() {
            return {
                displayerrorPasswordMessage:false,
                submitted:false,
                newMember:{}
            }
    },
    methods:
    {
        loadTeam(){
            this.$store.dispatch('contractor/loadTeamList');
        },
        async add()
        {
        if(this.newMember.password.length>7)
        {
            this.submitted=true;
            this.displayerrorPasswordMessage=false;
            var res=await this.$store.dispatch('contractor/addTeamMember',this.newMember)
            this.submitted=false;
            let vm=this;
            setTimeout(function(){vm.loadTeam()}, 1000)
            this.$router.push("/dashboard/equipe")
        }
        else{
            this.displayerrorPasswordMessage=true;
        }
        },
    }
}
</script>
<style scoped>

.update-msg{
      font-size: 12px;
      color: green;
      margin-top: 15px;
}
.error-msg{
      font-size: 12px;
      color: red;
      margin-top: 15px;
}
img{
    margin-top: -120px;
    margin-bottom: 20px;   
    padding: 10px; 
}   
.form{
    text-align: center;
    padding: 10px;
    margin:auto;
} 
.col-md-12
{
    padding-bottom: 15px;
}
input,textarea
{
    background-color: #f1f3f4; 
}
input:focus,textarea:focus
{
    outline: none;
    box-shadow: 0px 0px 2px #2d3748;
}
.form-control{
    border: none;
}
label
{
    padding-left: 12px;
    float: left;
}
</style>
