<template>
    <div>
        <div v-for="category in categories" :key="category.id">
            <card class="mycard">
                <div class="row rowContainer">
                    <div class="col-lg-6 col-md-6 col-6">{{category.name}}</div>
                    <div class="col-lg-4 col-md-4 col-3">{{category.ranking}} </div>
                    <div class="col-lg-2 col-md-2 col-3 editIcons">
                        <a class="button"  @click="deleteCategory(category)">
                            <h6 class="fa fa-close icon"></h6> 
                        </a>
                        <a class="button"  @click="edit(category)">
                            <h6 class="fa fa-edit icon"></h6> 
                        </a>   
                    </div>
                </div>
            </card>
        </div>
        <popup-category v-if="edit3" :edit3='edit3' :add3='add3'> </popup-category>
        <popup-confirmation v-if="confirmation"></popup-confirmation>

    </div>
</template>
<script>
import config from '@/api/config.js'
import popupCategory from '@/components/Popups/popupCategory'
import EventBus from '@/eventBus'
import popupConfirmation from '@/components/Popups/popupConfirmation.vue'

export default {
data(){
        return {
            edit3:false,
            add3:false,
            confirmation:false
        }
},
components:{
popupCategory,
popupConfirmation
},
computed:{
    configuration(){
        return config
    },
    organization() {
        return this.$store.getters['contractor/getfilteredMembers'];
    },
    categories(){
        return this.$store.getters['contractor/getCategories'];
    },
},
methods:{
    edit(category){
        this.edit3=true
        this.$store.dispatch('contractor/getCategoryElement',category)
    },
    deleteCategory(category){
        this.confirmation=true;
        let vm=this;
        EventBus.$on('DELETE', (payload) => {
        this.confirmation=payload.opened
        if(payload.deleted){
            this.$store.dispatch('contractor/deleteCategory',category)
            setTimeout(function(){vm.$store.dispatch('contractor/loadConfig')}, 500)
        }
        this.confirmation=false;
        EventBus.$off('DELETE')
        })
    }
},
created(){
    EventBus.$on('ADD_CATEGORY', (addOff) => {
        this.add3=addOff;
        this.edit3=addOff
    })
}
}
</script>
<style scoped>
.mycard:hover{
    background-color: #8f919417;
    cursor: pointer;
}
.rowContainer > div{
    text-align: center;
    color: #2d3748;
}
.fa-close{
    color: red !important;
}
.icon{
    visibility: hidden;
    float: right;
    color: #2d3748;
    font-weight: bold;
}
@media screen and (max-width:999px) {
    .icon{
        visibility: visible;
    }
    .rowContainer > div{
    padding: 0px 5px 0px 5px;
}
}
</style>
<style lang="scss" scoped>
.mycard:hover .icon{
    visibility: visible;
    cursor: pointer;
}
</style>