<template>
    <div>
        <div class="row"><p class="col-12"> <el-button type="primary" class="add" round v-on:click="switchOn()">Ajouter un évenement</el-button></p></div>
            <popup-form v-if="add1" :edit1='edit1' :add1='add1'>
            </popup-form>
            <popup-form v-if="edit1" :edit1='edit1' :add1='add1'>
            </popup-form>
        <div v-if="currentEvent!=null">
        <card class="card" v-for="event in currentEvent" :key="event.id">
            <div>
                <div class="row" v-if="event.startDate && event.endDate">
                    <p class="col-lg-10 col-8 date">{{getOnlyDate(event.startDate)}} -  <span class="heure"> {{getHour(event.startDate)}} - {{getHour(event.endDate)}} </span></p>
                    <div class="col-lg-2 col-4">
                        <a class="button"  @click="deleteEvent(event)">
                            <h6 class="fa fa-close icon"></h6> 
                        </a>
                        <popup-confirmation v-if="eventOpened"></popup-confirmation>
                        <a class="button"  @click="edit(event)">
                            <h6 class="fa fa-edit icon"></h6> 
                        </a>   
                    </div>
                </div>
                <p>{{event.summary}}</p>

            </div>
        </card>
        </div>
        <div v-if="!currentEvent">
            <card class="card">
                <div>Rien n'est planifié</div>
            </card>
        </div>
        
    </div>
</template>
<script>
import EventBus from '@/eventBus'
import moment from 'moment';
import PopupForm from '@/components/Popups/PopupForm.vue'
import popupConfirmation from '@/components/Popups/popupConfirmation.vue'

export default {
    data(){
        return {
            edit1:false,
            add1:false,
            eventOpened:false,
            eventDeleted:false,
        }
    },
    components:{
        PopupForm,
        popupConfirmation
    },
    
    computed:{
        operationID () {
        return this.$route.params.id
        },
        currentEvent()
        {
        return this.operation.events       
        },
        operation () {
        return this.$store.getters['contractor/getCurrentOperation']
        }
    },
    methods:{
        getHumanDate : function (date) 
        {
        return moment(date).format('DD/MM/YYYY HH:mm');
        },
        getHour : function (date) 
        {
        return moment(date).format('HH:mm');
        },
        getOnlyDate : function (date) 
        {
        return moment(date).format('DD/MM/YYYY');
        },
        navigateTo (nav) {
        this.$router.push(nav)
        },
        switchOn(){
        this.add1=true;
        this.edit1=false;
        },
        edit(event){
        this.add1=false;
        this.edit1=true;
        var statusEnd = moment(event.endDate).add(0, 'hours').format()
        var statusStart = moment(event.startDate).add(0, 'hours').format()
        event.endDate=statusEnd
        event.startDate=statusStart
        this.$store.dispatch('contractor/getNewEvent',event)
        },
        loadOperation(){
        this.$store.dispatch('contractor/refreshOperationbyId',this.operationID)
        },
        deleteEvent(event){
        this.eventOpened=true;
        EventBus.$on('DELETE', (payload) => {
        this.eventOpened=payload.opened
        if(payload.deleted){
            this.$store.dispatch('contractor/deleteEvent',event)
            let vm=this;
            setTimeout(function(){vm.loadOperation()}, 500)
        }
        EventBus.$off('DELETE')
        })
        }
    },
    mounted(){
        EventBus.$on('ADD_EVENT', (addOff) => {
        this.add1=addOff;
        this.edit1=addOff
        })
    }
}
</script>
<style scoped>
.fa-close{
    color: red !important;
}
.icon{
    visibility: hidden;
    float: right;
    color: #2d3748;
    font-weight: bold;
}
.card:hover{
    background-color: #8f919417;
}

.date,.heure{
    color: #eebb4d;
}
.date,.titre{
    font-weight: bold;
}
.heure{
    font-size: 14px;
    color: black;
    font-weight: 100;
}
.titre{
    font-size: 18px;
    color:#2d3748
}
p {
    margin-bottom: 10px;
    padding-left: 18px;
}
.userPicture{
    position: relative;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    margin-top: 3px;
    right: 8px;
    z-index: 10;
}
.user{
    font-size: 14px;
    float: right;
}
.button{
    padding:0px 2px 0px 2px;
}
.add{
    float: right;
}
@media screen and (max-width: 400px){
 .user{
     padding: 0px;
     font-size: 12px;
 }  
 .titre{
     padding-left: 5px;
 } 
}
@media screen and (max-width: 1000px){
 .icon{
     visibility: visible;
 }   
}
</style>
<style lang="scss" scoped>
.card:hover .icon{
    visibility: visible;
    cursor: pointer;
}
</style>
