<template>
  <div class="sidebar"
       :data-background-color="backgroundColor"
       :data-active-color="activeColor">
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a class="button" @click="toggleSidebar">
            <h6 class="xbutton ti-close"></h6> 
        </a>
        <a href="/home" class="simple-text text-center">
            <img class="logo-image" src="@/assets/img/proximov-logo-white.png" alt="">
        </a>        
      </div>
      <slot>

      </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-link v-for="(link,index) in sidebarLinks"
                        :key="index"
                        :to="link.path"
                        :name="link.name"
                        :icon="link.icon"
                        class="sidebarstyle">
          </sidebar-link>
        </slot>
      </ul>
      <moving-arrow :move-y="arrowMovePx" id="movingArrow">

      </moving-arrow>
    </div>
  </div>
</template>
<script>
import MovingArrow from "./MovingArrow.vue";
import SidebarLink from "./SidebarLink";
export default {
  props: {
    title: {
      type: String,
      default: "Proximov"
    },
    backgroundColor: {
      type: String,
      default: "darkblue",
      validator: value => {
        let acceptedValues = ["white", "black", "darkblue"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink
    };
  },
  components: {
    MovingArrow,
    SidebarLink
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      return (this.linkHeight) * this.activeLinkIndex ;
    },
    routeName() {
      const { name } = this.$route;
      if(name === 'profile')
      {
            document.getElementById(text).style.display = 'none';
      }
    }
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: []
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    addLink(link) {
      const index = this.$slots.links.indexOf(link.$vnode);
      this.links.splice(index, 0, link);
    },
    removeLink(link) {
      const index = this.links.indexOf(link);
      if (index > -1) {
        this.links.splice(index, 1);
      }
    }
  },
  mounted() {
    this.$watch("$route", this.findActiveLink, {
      immediate: true
    });
  }
};
</script>
<style scoped>
.xbutton{
  color: #ffffff;
  opacity: 1;
  float: right;
  margin-top: 18px;
  font-weight: bold;
  margin-right:40px;
}
.logo-image {
  height: 20px;
}
@media screen and (min-width: 991px){
.button
{
  display: none
}
.logo{
  padding-bottom: 40px;
}
}

</style>
