import {
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_ACCOUNT,
  INIT_AUTH_STATE,
  WEAK_PASSWORD,
  GET_PROFILE_BY_CONTRACTOR_SUCCESS,
  GET_PROFILE_BY_CONTRACTOR_FAILURE,
  SET_PROFILE_BY_CONTRACTOR_SUCCESS,
  SET_PROFILE_BY_CONTRACTOR_FAILURE,
  GET_LANDING_SUCCESS,
  GET_LANDING_FAILURE,
  UPDATE_LANDING_SUCCESS,
  UPDATE_LANDING_FAILURE,
  UPDAYE_PW_SUCCESS,
  UPDAYE_PW_ERROR

} from '../../mutation-types'

export const mutations = {
  [SIGNUP]: function (state) {
    state.displayRegisFailureMessage = false
  },
  [WEAK_PASSWORD]: function (state) {
    state.passwordFailureMessage = true
  },
  [SIGNUP_SUCCESS]: function (state) {
    state.isRegistered = true
    state.displayRegisSuccessMessage = true
  },
  [LOGIN]: function (state) {
    state.displayAuthFailureMessage = false
  },
  [SIGNUP_FAILURE]: function (state) {
    state.displayRegisFailureMessage = true
  },
  [LOGIN_SUCCESS]: function (state) {
    state.isAuthenticated = true
    state.displayAuthSuccessMessage=true
    state.displayAuthFailureMessage = false
  },
  [SET_ACCOUNT]: function (state, payload) {
    state.account = payload
  },
  [LOGIN_FAILURE]: function (state) {
    state.displayAuthFailureMessage = true
    state.displayAuthSuccessMessage=false

  },
  [LOGOUT]: function (state) {
    state.isAuthenticated = false
  },
  [INIT_AUTH_STATE]: function (state, payload) {
    state.isAuthenticated = true
    state.account = payload
  },
  [GET_PROFILE_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
    state.profile = payload
  },
  [GET_PROFILE_BY_CONTRACTOR_FAILURE]: function (state) {
    state.profileLoaded=false;
  },
  [SET_PROFILE_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
    state.profile = payload
    state.profileLoaded=true;
  },
  [SET_PROFILE_BY_CONTRACTOR_FAILURE]: function (state) {
    state.profileLoaded=false;
  },
  [UPDAYE_PW_SUCCESS]: function (state, payload) {
    //
  },
  [UPDAYE_PW_ERROR]: function (state) {
    //
  }
  
    
  
}
