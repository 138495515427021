<template>
<div class="autocomplete-wrapper">
    <autocomplete
        :search="search"
        placeholder="Chercher une opération"
        aria-label="Chercher une opération"
        @submit="handleSubmit"
  >
    <template #result="{ result, props }">
      <li
        v-bind="props"
        class="autocomplete-result search-result"
      >

        <div class="operation-itme">
             <div class="row head">
                        <span class="col-6 operationId"><font color="#5fd5b7">No </font> {{result.reference}}</span> 
                        <div class="status col-6"><span class="h5" v-bind:style="statusBgColor(result.status)"> <font color="#ffffff"> {{result.status}} </font>  </span></div>
                    </div>
                    <div class="row">
                            <p class="col-12 titre-mobile">{{titleSplit(result.title)}}</p>                                <!--
                                <div class="col-md-6 col-sm-6 col-5"> <p class="category"> {{operation.category}} </p> </div>
                                -->
                                <div class="col-6 creation-date-mobile"><p>Créé le {{result.createdAt.substr(0,10)}} </p></div>
                                <div class="col-6 creation-date-mobile"><p>Ville  {{result.location.city}} </p></div>

            </div>
        </div>
      </li>
    </template>
  
  </autocomplete>
  </div>
</template>
<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import Api from '@/api'
import configColor from '@/api/config.js'

export default {
    data: function() {
            return { }
    },
    components: {
        Autocomplete
    },
    computed:{
    },
    methods:{
        async search (input) {
            if(input.length < 3 ) {
                return []
            }
            else {
                return await Api.searchOperation(input)
            }
        },
        handleSubmit (result) {
            this.$store.dispatch('contractor/setOperationId',result.id);
            this.$router.push('/dashboard/operations/'+result.id);
        },
        statusBgColor(status)
        {
            for(var i=0;i<configColor.Status.length;i++){
                if(configColor.Status[i].name==status)
                {
                    return { 
                    'background-color':configColor.Status[i].color,
                    'border-color':configColor.Status[i].color,
                    'color':'white'
                    };
                }
            }            
        },
        priorityColor(priority)
        {
        for(var i=0;i<configColor.Priorities.length;i++){
            if(configColor.Priorities[i].name==priority)
            {
                return { 
                'color':configColor.Priorities[i].color,
                };
            }
        }
        },
        titleSplit(title){
            var stringTitle='';
            if(title && title.length>40)
            {
                for(var i=0;i<5;i++){
                    stringTitle=stringTitle+' '.concat(title.split(" ")[i])
                }
                return stringTitle
            }
            else return title
            
            console.log(title.length)
            
        }
    }
}
</script>

<style scoped>
.autocomplete-wrapper {
    width: 100%;
}
.search-result {
  border-top: 1px solid #eee;
  padding: 10px;
  background: transparent;
}

.desktop-style{
    display: block;
    height: 65px;
}
.mobile-style {
    display: none;
    box-shadow: 0 2px 2px rgb(204 201 220 / 49%);
    height: 130px;
}

.titre-mobile {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.creation-date-mobile p{
    font-size: 12px;
    color: gray;
}
.mobile-style hr {
    border-top:1px solid #f1f3f4;
    margin : 10px 0px;
}
.createdat{
    padding-right: 0px;
    color: gray;
}

.operationId {
    font-weight: 600;
}
#datecreation{
    font-size: 12px !important;
}
.userPicture{
    border-radius: 15px;
    width: 25px;
    height: 25px;
}
.searchInput{
    padding:auto;
    padding-left: 20px;
    width: 30%;
    margin: 0px;
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    float: left
}
.card:hover{
    background-color: #8f919417;
    cursor: pointer;
}
.card{
  margin-top:10px;
  border: none;
  font-size: 14px;
  border-radius: 10px;
}
hr {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
p{
    margin-bottom: 0px;
  font-size:14px; 
}
.status{
    float: left;
    font-weight: 700;
    line-height: 99%;
    text-transform: uppercase;
}
.h5{
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    padding:2px 10px;
}
.priorité{
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
}
.row{
    padding-left: 5px;
}
.user{
    float: right;
    padding: 0px;
}
.catDesktop{
    padding: 0px;
    padding-left: 10px;
    margin: auto;
}
.cat,.category{
    color: #eebb4d;
    padding: 0px;
    font-size: 13px;
}
.titre{
    padding-left: 20px;
    font-size: 14px;
}
.titreDesktop,.userDesktop,.operationId{
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
}
.userDesktop{
    padding: 0px;
    text-align: center
}
</style>
