export const getters = {
  isAuthenticated: function (state) {
    return state.isAuthenticated
  },
  displayAuthFailureMessage: function (state) {
    return state.displayAuthFailureMessage
  },
  displayRegisFailureMessage: function (state) {
    return state.displayRegisFailureMessage
  },
  displayRegisSuccessMessage:function(state) {
    return state.displayRegisSuccessMessage
  },
  displayAuthSuccessMessage:function(state) {
    return state.displayAuthSuccessMessage
  },
  passwordFailureMessage:function(state) {
    return state.passwordFailureMessage
  },
  emailFailureMessage:function(state){
    return state.emailFailureMessage
  },
  getUser: function (state) {
    return state.account
  },
  getProfile: function(state) {
    return state.profile
  },
  displayUpdateMessage: function (state) {
    return state.profileLoaded
  }
}
