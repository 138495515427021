<template>
    <modal name="note-modal" class="eventModal" :scrollable='false' :resizable='true' height="auto" :adaptive="true" :clickToClose='false'>
        <card class="card" >     
            <form @submit.prevent="Upload()">    
                <div class="row">
                    <a class="button col-12" @click="hide">
                        <h6 class="xbutton ti-close"></h6> 
                    </a>
                    <div class="col-12">
                        <label >Titre</label>
                        <input type="text"
                        placeholder="Titre"
                        name="titre"
                        v-model="note.title"
                        class="form-control" />
                    </div>
                    <div class="col-12">
                        <label >Description</label>
                        <textarea type="text" placeholder="Description" id="textarea" name="desciption" class="form-control" rows="5" v-model="note.content" required/> 
                    </div>
                </div>
                <div class="text-center">              
                    <p-button v-if="addnt" type="primary" round :loading='submitted'>Ajouter</p-button>
                </div>
            </form>
            <form @submit.prevent="editNote()">
                <div class="text-center">   
                    <p-button v-if="editnt || !addnt" type="primary" round :loading='submitted'>Modifier</p-button> 
                </div>
            </form>
    </card>
    </modal>
</template>
<script>
import EventBus from '@/eventBus'
export default {
    data: function() {
            return {  
            desciptionNote:'',
            titleFile:'',
            submitted: false,
            switch:false
            }
    },
    props:{addnt:Boolean,editnt:Boolean},
    computed:{
        operationID () {
        return this.$route.params.id
        },
        operation () {
        return this.$store.getters['contractor/getCurrentOperation']
        },
        note(){
        return this.$store.getters['contractor/getNote']
        }
    },
    methods:{
        show () {
            this.$modal.show('note-modal');
            EventBus.$emit('ADD_NOTE', this.switch);     
        },
        hide () {
            this.$modal.hide('note-modal');
            EventBus.$emit('ADD_NOTE', this.switch);
            
        },
        close(){
            this.$modal.close('note-modal');
            EventBus.$emit('ADD_NOTE', this.switch);
        },
        async Upload(){
        this.submitted=true;
        let payload={
          id : this.operationID,
          title: this.note.title,
          description: this.note.content,
        }
        await this.$store.dispatch('contractor/addNote',payload);
        this.submitted=false;
        this.$store.dispatch('contractor/loadOperationbyId',this.operationID)
        this.hide();
        },
        async editNote(){
            this.submitted=true;
            let payload={
            id : this.operationID,
            title: this.note.title,
            description: this.note.content,
            idNote:this.note.id
        }
        await this.$store.dispatch('contractor/editNote',payload);
        this.submitted=false;
        this.hide();
        
        }
    },
    mounted () {
        this.$modal.show('note-modal');
    },
    created(){
        let that=this;
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.hide();
                that.close();
            }
        });
        
    }
}
</script>

<style scoped>
#textarea{
    background-color: #F1F3F4 !important;
    height: inherit;
}
.bg-white{
    position: relative;
    margin-bottom: 20px
}
i {
  position: absolute;  
  font-family: FontAwesome;
  margin: 0 auto;
  font-size: 1rem;
  font-style: normal;
  left: 17px;
  padding: 2px;
  bottom: 17px;
  color: gray;
}
.form-control{
    position: relative;
}
.vm--container::v-deep .vm--modal{
    left: 500px !important;    
}
@media screen and (max-width: 1100px) {
  .vm--container::v-deep .vm--modal{
    left: 0px !important;
    /*top: 800px !important*/
    margin-top: 50%
}
.vm--container::v-deep .vm--overlay{
   /* top: 700px !important;*/
    height: 100% !important;
    margin-top: 50%
}
}
</style>
