<template>
  <div>
    
    <!-- Operations stats-->
    <div class="row">
       <stats></stats>
    </div>

    <!--Stats cards-->
    <div class="row">
      <div class="col-md-6 col-xl-3" id="mystats" v-for="stats in statsCards" :key="stats.title">
        <stats-card>
          <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
            <i :class="stats.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{stats.title}}</p>
            {{stats.value}}
          </div>
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i> {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>

    <!--Recent Activity-->
    <div class="row">

      <div class="col-12">
      </div>

    </div>

  </div>
</template>
<script>
import Operation from '@/pages/Operations/OperationList'
import List from '@/pages/Operations/List.vue'
import { StatsCard, ChartCard } from "@/components/index";
import Chartist from 'chartist';
import stats from '@/pages/Operations/stats'
export default {
  components: {
    StatsCard,
    ChartCard,
    stats,
    Operation,
    List
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      statsCards: [
        {
          type: "warning",
          icon: "ti-server",
          title: "Capacity",
          value: "1GB",
          footerText: "Updated now",
          footerIcon: "ti-reload"
        },
        {
          type: "success",
          icon: "ti-wallet",
          title: "Revenue",
          value: "$1,34",
          footerText: "Last day",
          footerIcon: "ti-calendar"
        },
        {
          type: "danger",
          icon: "ti-pulse",
          title: "Errors",
          value: "23",
          footerText: "In the last hour",
          footerIcon: "ti-timer"
        },
        {
          type: "info",
          icon: "ti-twitter-alt",
          title: "Followers",
          value: "+45",
          footerText: "Updated now",
          footerIcon: "ti-reload"
        }
      ],
    };
  },
  created(){
    let options = {
            limit: 10,
            start: 0
        }   
        this.$store.dispatch('contractor/loadOperations',options);
  }
};
</script>
<style>
/** 
#mystats{
padding-left: 30px;
padding-right: 50px;
} 
*/
</style>
