<template>

  <div :class="{'nav-open': $sidebar.showSidebar}" id="app">
    <notifications></notifications> 
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    (function() {
    window.ybug_settings = {"id":"t5p8abr5g9mc49ganapj"};
    var ybug = document.createElement('script'); ybug.type = 'text/javascript'; ybug.async = true;
    ybug.src = 'https://widget.ybug.io/button/'+window.ybug_settings.id+'.js';
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ybug, s);
})();
  }
};
</script>

<style lang="scss">
.vue-notifyjs.notifications {
  .alert {
    z-index: 10000;
  }
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
  #app{
    font-family: Nunito, sans-serif !important;
  }
}
</style>
