<template>
<div class="container">
    <card class="card" >
        <b-card-header>
            <h5>Mise à jour du mot de passe</h5>
        </b-card-header>            
            <form @submit.prevent="reset()">
                <div class="row">
                    <div class="col-md-12">
                        <label >Mot de passe actuel</label>
                        <input type="password"
                        placeholder=""
                        v-model="oldpassword"
                        name="oldpassword"
                        class="form-control"
                        id="pw1" />
                        <span  class="fa fa-fw fa-eye field-icon toggle-password" @click="showPassword"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Nouveau mot de passe</label>
                        <input type="password" id="pw2" v-model="password" name="password" class="form-control" /> 
                        <span  class="fa fa-fw fa-eye field-icon toggle-password" @click="showPassword1"></span>
                    </div>          
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Confirmer votre nouveau mot de passe</label>
                        <input type="password" id="pw3" v-model="password1" name="password1" class="form-control" /> 
                        <span  class="fa fa-fw fa-eye field-icon toggle-password" @click="showPassword2"></span>
                    </div>        
                </div>               
                <div class="text-center"> 
                        <p v-if="!passwordsmatch" class="auth-failure-msg">Merci de vérifier que vos mots de passe sont identiques.</p>                                                  
                    <p-button type="primary" :round="true" :loading='submitted' class="updateButton">Mettre à jour</p-button>
                </div>
            </form>
    </card>
</div>
</template>
<script>
export default {
    data: function() {
            return {
            oldpassword:'',
            password: '',
            password1: '',
            submitted:false,
            passwordsmatch:true
            }
        },
    methods:
    {
        async reset()
        {
            if(this.password == this.password1){
                this.submitted=true;
                await this.$store.dispatch('auth/updatePassword',this.password);
            }
            else{
                this.match=false;
                this.passwordsmatch=false;
            }
            this.submitted=false
        },
        showPassword()
            {
            var temp2 = document.getElementById("pw1"); 
            if (temp2.type === "password") { 
                temp2.type = "text";
            } 
            else { 
                temp2.type = "password";
            } 
        },
        showPassword1()
            {
            var temp2 = document.getElementById("pw2"); 
            if (temp2.type === "password") { 
                temp2.type = "text";
            } 
            else { 
                temp2.type = "password";
            } 
        },
        showPassword2()
            {
            var temp2 = document.getElementById("pw3"); 
            if (temp2.type === "password") { 
                temp2.type = "text";
            } 
            else { 
                temp2.type = "password";
            } 
        } 
    }    
    
}
</script>
<style scoped>
input
{
    background-color: #f1f3f4; 
}
input:focus
{
    background-color: #f1f3f4; 
    outline: none;
    box-shadow: 0px 0px 2px #2d3748;
}
.form-control{
    background-color: #f1f3f4;
    border: none;
}
.container{
    padding: 10px;
}   
#profileCard{
    padding-right: 0px;
    padding-left: 0px
}
.form-control{
    background-color: #f1f3f4;
    border: none;
    border-radius:10px;    
}
label
{
    padding-left: 12px;
    float: left;
}
</style>
