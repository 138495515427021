<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/home" name="Accueil" icon="ti-home" :comingsoon="false" />
        <sidebar-link to="/dashboard/operations" name="Opérations" icon="ti-settings" :comingsoon="false" />
        <sidebar-link to="/dashboard/myOperations" name="Mes opérations" icon="ti-target" :comingsoon="false" />
        <sidebar-link to="/dashboard/calendrier" name="Calendrier" icon="ti-calendar" :comingsoon="false" />
        <sidebar-link to="/dashboard/equipe" name="Équipe" icon="fa fa-group" :comingsoon="false" />
        <sidebar-link to="/dashboard/rapports" name="Rapports" icon="ti-file" :comingsoon="true" />
        <sidebar-link to="/dashboard/crm" name="Carnet clients" icon="fa fa-suitcase" :comingsoon="false" />
        <sidebar-link to="/dashboard/landingPage" name="Vitrine" icon="ti-location-pin" :comingsoon="false" />
      </template>
      <mobile-menu></mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import AuthService from '@/auth/AuthService';
export default {

data() {
    return {
      islogged:AuthService.checkAuth("contractor"),
      statusFilter:['Nouveau','Prête',"Préparation","En cours","Planifiée"]
    };
  },  
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    
  },
  computed:{
    operations(){
      return this.$store.getters['contractor/getOperations'];
    }
  },
  methods: {
      toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  // Log the user out
      logout() {
      this.$store.dispatch('auth/logout')
      },
      getOperationsCoordinates(){
      this.$store.dispatch('contractor/loadMap');
    },
  },
  async created(){
    await this.$store.dispatch('contractor/loadTeamList'); 
    let vm=this;
    setTimeout(function(){vm.$store.dispatch('contractor/loadMap')}, 2000)
    setTimeout(function(){vm.$store.dispatch('contractor/loadConfig')}, 1000)
    this.$store.dispatch('contractor/loadLanding');
    this.$store.dispatch('auth/loadProfile'); 
    let options = {
    limit: 20,
    start: 0,
    status:this.statusFilter,  
    priority:'',
    category:'',
    firstName:'',
    lastName:''
    }   
    this.$store.dispatch('contractor/loadEvents',options);
    this.$store.dispatch('contractor/loadOperations',options);
    this.$store.dispatch('contractor/loadMap');
  }
};
</script>
<style>

.nav-item{
  padding-left: 10px;
}

</style>
