<template>
    <div>
        <div v-for="priority in priorities" :key="priority.ranking">
            <card class="mycard">
                <div class="row rowContainer">
                    <div class="col-lg-4 col-md-4 col-4">{{priority.name}}</div>
                    <div class="col-lg-4 col-md-4 col-3" v-bind:style="'background-color:'+priority.color"></div>
                    <div class="col-lg-2 col-md-2 col-2">{{priority.ranking}} </div>
                    <div class="col-lg-2 col-md-2 col-3 editIcons">
                        <a class="button"  @click="deletePriority(priority)">
                            <h6 class="fa fa-close icon"></h6> 
                        </a>
                        <a class="button"  @click="edit(priority)">
                            <h6 class="fa fa-edit icon"></h6> 
                        </a>   
                    </div>
                </div>
            </card>
        </div>
        <popup-priority v-if="edit1" :edit1='edit1' :add1='add1'> </popup-priority>
        <popup-confirmation v-if="confirmation"></popup-confirmation>
    </div>
</template>

<script>
import config from '@/api/config.js'
import popupPriority from '@/components/Popups/popupPriority'
import EventBus from '@/eventBus'
import popupConfirmation from '@/components/Popups/popupConfirmation.vue'


export default {
    data(){
        return {
            edit1:false,
            add1:false,
            confirmation:false
        }
},
components:{
    popupPriority,
    popupConfirmation
},
computed:{
    configuration(){
        return config
    },
    priorities(){
        return this.$store.getters['contractor/getPriorities'];
    },
    configurationId() {
        return this.$store.getters['contractor/getConfigurationId'];
    },
    organization() {
        return this.$store.getters['contractor/getfilteredMembers'];
    },
},
methods:{
    edit(priority){
        this.edit1=true
        this.$store.dispatch('contractor/getPriorityElement',priority)
    },
    deletePriority(priority){
        this.confirmation=true;
        let vm=this;
        EventBus.$on('DELETE', (payload) => {
        this.confirmation=payload.opened
        if(payload.deleted){
            this.$store.dispatch('contractor/deletePriority',priority)
            setTimeout(function(){vm.$store.dispatch('contractor/loadConfig')}, 500)
        }
        this.confirmation=false;
        EventBus.$off('DELETE')
        })
    }
},
created(){
    EventBus.$on('ADD_PRIORITY', (addOff) => {
        this.add1=addOff;
        this.edit1=addOff
        })
}
}
</script>
<style scoped>
.mycard:hover{
    background-color: #8f919417;
    cursor: pointer;
}
.rowContainer > div{
    text-align: center;
    color: #2d3748;
}
.fa-close{
    color: red !important;
}
.icon{
    visibility: hidden;
    float: right;
    color: #2d3748;
    font-weight: bold;
}
@media screen and (max-width:999px) {
    .icon{
        visibility: visible;
    }
    .rowContainer > div{
    padding: 0px 5px 0px 5px;
}
}
</style>
<style lang="scss" scoped>
.mycard:hover .icon{
    visibility: visible;
    cursor: pointer;
}
</style>