export const getters = {
  getOperations: function (state) {
    return state.operations
  },
  getOperationId: function(state) {
    return state.operationId
  },
  getNewOperation: function(state) {
    return state.newOperation
  },
  displayAddMessage: function(state) {
    return state.operationAdded
  },
  getCurrentOperation (state) {
    return state.currentOperation
  },
  assigned (state){
    return state.assigned
  },
  getStatusCount(state){
    return state.statusCounts
  },
  operationsLoaded: function (state) {
    return state.operationsLoaded
  },
  getfilteredOperations:function(state) {
    return state.filteredOperations
  },
  getfilteredMembers:function(state) {
    return state.filteredMembers
  },
  getTeamMembers:function(state){
    return state.teamMembers
  },
  getTeamMembers2:function(state){
    return state.teamMembers2
  },
  teamLoaded: function (state) {
    return state.teamLoaded
  },
  getClients: function (state) {
    return state.clients
  },
  getfilteredClients: function (state) {
    return state.filteredClients
  },
  getOpEvents: function(state) {
    return state.OperationEvents
  },
  getSelectedDate: function(state) {
    return state.selectedDate
  },
  getCurrentEvent: function(state) {
    return state.currentEvent
  },
  getAssigneeEvent: function(state) {
    return state.assigneeEvent
  },
  getOperationsEvents: function(state) {
    return state.allEvents
  },
  getNewEvent: function(state) {
    return state.newEvent
  },
  getMedia: function(state){
    return state.media
  },
  getUploadStatus: function(state){
    return state.eventUploaded
  },
  getConfigurationId: function(state){
    return state.configurationId
  },
  getOrganizationId: function(state){
    return state.organizationId
  },
  getPriorities: function(state){
    return state.priorities
  },
  getCategories: function(state){
    return state.categories
  },
  getStatuses: function(state){
    return state.statuses
  },
  getElementPriority: function(state){
    return state.ElementPriority
  },
  getElementStatus: function(state){
    return state.ElementStatus
  },
  getElementCategory: function(state){
    return state.ElementCategory
  },
  getNote:function(state){
    return state.note
  },
  getLanding:function(state){
    return state.landing
  },
  landingMsg:function(state){
    return state.landingUpdated
  },
  getfilteredAssignedOperations:function(state){
    return state.filteredassignedOperations
  },
  getCoords: function(state){
    return state.mapCoords
  }
}
