<template>
<div class="col-12">
    <resetpassword></resetpassword>
    <card class="card" id="profileCard" >
         <b-card-header>
            <h5>Mise à jour du profil</h5>
        </b-card-header>
            <form @submit.prevent="update()">  
                <div class="row">
                    <div class="col-md-12 col-12">
                        <label >Email</label>
                        <input type="text"
                        placeholder="Email"
                        v-model="profile.email"
                        name="email"
                        class="form-control" readonly/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-12">
                        <label>Prénom</label>
                        <input type="text" placeholder="Prénom" v-model="profile.firstName" name="prénom" class="form-control" /> 
                    </div>          
                </div>
                <div class="row">
                    <div class="col-md-12 col-12">
                        <label>Nom</label>
                        <input type="text" placeholder="Nom" v-model="profile.lastName" name="nom" class="form-control" />   
                    </div>        
                </div>
                <div class="row">
                    <div class="col-md-12 col-12">
                        <label>Téléphone</label>
                        <input type="tel" placeholder="Téléphone" v-model="profile.phoneNumber" name="téléphone" class="form-control" />   
                    </div>       
                </div>
                <div class="text-center">              
                    <p-button type="primary" :round="true" :loading='submitted' class="updateButton">Mettre à jour</p-button>
                </div>
            </form>
    </card>
    </div>
</template>
<script>

export default {
    name:'profile',
    data: function() {
            return {   
            submitted:false
        }
        },
    computed:
    {
      userProfile () {
        return this.$store.getters['auth/getUser']
      },
      profile() {
        return this.$store.getters['auth/getProfile'];
      },
      displayUpdateMessage: function () {
        return this.$store.getters['auth/displayUpdateMessage']
    }
    }, 
    methods:
    {
    async update(){
        this.submitted=true
        await this.$store.dispatch('auth/updateProfile');
        this.submitted=false
        await this.$store.dispatch('auth/loadProfile'); 
    }
    }  
}
</script>
<style scoped>
.update-msg{
      font-size: 15px;
      color: green;
      margin-top: 15px;
}
.updateButton{
    margin-right: 10px;
}

img{
    margin-top: -120px;
    margin-bottom: 20px;   
    padding: 10px; 
}   
.form{
    text-align: center;
    padding: 10px;
    margin:auto;
} 
.col-md-12
{
    padding-bottom: 15px;
}
input
{
    background-color: #f1f3f4; 
}
input:focus
{
    background-color: #f1f3f4; 
    outline: none;
    box-shadow: 0px 0px 2px #2d3748;
}
.form-control{
    background-color: #f1f3f4;
    border: none;
}
label
{
    padding-left: 12px;
    float: left;
}

.container{
    padding: 10px;
}   
#profileCard{
    padding-right: 0px;
    padding-left: 0px
}
</style>
