// import axios from 'axios'
import auth0Library from 'auth0-js'
import router from '../router'
import axios from 'axios'

let ssoURI = process.env.SSO_API_URI
/* eslint no-undef: "off" */
const auth0_contractor = new auth0Library.WebAuth({
    clientID: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
    domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN,
    //redirectUri: 'http://proximov.local:8080/auth/callback',
    redirectUri: location.origin + '/auth/callback',
    responseType: 'token id_token',
    // scope: 'openid'
})  
/**
 * 
 * @param {*} type
 * @param {*} email 
 * @param {*} password 
 * @param {*} callback 
 */
let login = (type, email, password, callback) => {
  switch(type){
    case 'contractor':
      auth0_contractor.login({   
        realm: 'Username-Password-Authentication',
        email: email,
        password: password
      },
      function (err) {
        if (err) callback(err)
      })
      break;
  }
}

/**
 * 
 * @param {*} type 
 * @param {*} email 
 * @param {*} password 
 * @param {*} callback 
 */
let loginWithKeycloak = (type, email, password, callback)  =>  {
  switch(type) {
    case 'contractor':  

    let authDataParam = new URLSearchParams()
    authDataParam.append('client_id', process.env.VUE_APP_SSO_CLIENT_ID)
    authDataParam.append('client_secret', process.env.VUE_APP_SSO_CLIENT_SECRET)
    authDataParam.append('grant_type', process.env.VUE_APP_SSO_GRANT_TYPE)
    authDataParam.append('scope', process.env.VUE_APP_SSO_SCOPE)
    authDataParam.append('username', email)
    authDataParam.append('password', password)
      axios.post(process.env.VUE_APP_SSO_API_URI, authDataParam, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }}).then(response =>{
        console.log(response)
        setKeycloakSession('contractor', response.data)
        router.replace("/home")
        return callback(null,{})
      }).catch(err =>{
        console.log(err);
        return callback(err)
      })

    break;
  }
}
/**
 * 
 * @param {*} email
 * @param {*} username 
 * @param {*} password 
 * @param {*} callback 
 */
let signup = (type, email, username, password, callback) => {
  switch(type){
    case 'contractor': 
      auth0_contractor.signup({   
        connection: 'Username-Password-Authentication',
        email: email,
        password: password,
        username: username
      },
      function (err) {
        if (err) {
          callback(err)
        }
        else{
          callback()
        }
      })
  
      break;
  }
}

/**
 * 
 * @param {*} type 
 * @param {*} callback 
 */
let handleAuthentication = function (callback) {
      auth0_contractor.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
            setSession('contractor', authResult)
            router.replace("/home")
            let profile = extractProfileFromPayload(authResult.idTokenPayload)
            return callback(null, profile)
        } else if (err) {
            router.replace("/auth/signin")
            console.log(err)  
           //   alert(`Error: ${err.error}. Check the console for further details.`)
            return callback(err)    
        }
      }) 
}
/**
 * 
 * @param {*} type 
 * @param {*} authResult 
 */
let setSession = function(type, authResult) {
  // Set the time that the access token will expire at
  let expiresAt = JSON.stringify
  (
    authResult.expiresIn * 1000 + new Date().getTime()
  )
      let profile = extractProfileFromPayload(authResult.idTokenPayload)
      localStorage.setItem('access_token', authResult.accessToken)
      localStorage.setItem('id_token',  authResult.idToken)
      localStorage.setItem('expires_at', expiresAt)
      localStorage.setItem('user_account', JSON.stringify(profile))

  if(type === 'contractor'){
    localStorage.setItem('user_type', 'contractor')
  }
  // this.authNotifier.emit('authChange', { authenticated: true })
}

let setKeycloakSession = function(type, authResult) {
  // Set the time that the access token will expire at
  let expiresAt = JSON.stringify
  (
    authResult.expires_in * 1000 + new Date().getTime()
  )
      //let profile = extractProfileFromPayload(authResult.idTokenPayload)
      localStorage.setItem('access_token', authResult.access_token)
      localStorage.setItem('id_token',  authResult.id_token)
      localStorage.setItem('expires_at', expiresAt)
      //localStorage.setItem('user_account', JSON.stringify(profile))

  if(type === 'contractor'){
    localStorage.setItem('user_type', 'contractor')
  }
  // this.authNotifier.emit('authChange', { authenticated: true })
}

let checkAuth = (type) => {
  if (localStorage.getItem('id_token') && localStorage.getItem('user_type') === type) {
    return true
  } else {
    return false
  }
}

/**
 * 
 */
let logout = () => {
  let type = localStorage.getItem('user_type')
  
  localStorage.removeItem('id_token')
  localStorage.removeItem('user_account')
  localStorage.removeItem('access_token')
  localStorage.removeItem('expires_at')
  localStorage.removeItem('user_type')
  
  switch(type){
    case 'contractor':
      router.replace("/auth/signin")
      break;
  }
}
/**
 * 
 * @param {*} payload 
 */
let extractProfileFromPayload = function (payload) {
  return {
    email: payload.email,
    name: payload.name,
    nickname: payload.nickname,
    picture: payload.picture,
    userId: payload.sub
  }
}

/**
 * 
 */
let getAccount = function() {
  return JSON.parse(localStorage.getItem('user_account'))
}

let getUserTokenId = function(){
  return localStorage.getItem('access_token')
}
export default {
  checkAuth,
  signup,
  login,
  logout,
  handleAuthentication,
  getAccount,
  getUserTokenId,
  loginWithKeycloak
}