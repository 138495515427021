<template>
    <div class="row">
      <div class="profile col-xl-12 col-lg-12 col-md-12">
        <profile-form>

        </profile-form>
      </div>
    </div>
</template>
<script>
import ProfileForm from "@/layout/dashboard/profile";
export default {
  components: {
    ProfileForm
  }
};
</script>
<style>
.profile{
  background-color: #f1f3f5;
}
</style>
