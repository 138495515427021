<template>
    <div id="mapContainer" style="height: 800px; width: 100%"> 
      <l-map
      :zoom="zoom"
      :center="getLatObject(mapCoord[0])"
      :options="mapOptions"
      style="height: 80%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <div v-for="coord in mapCoord">
        <l-marker :lat-lng="getLatObject(coord)">
          <l-tooltip :options="{ permanent: true, interactive: true }">
            <div @click="innerClick(coord)">
              {{titleSplit(coord.title)}}
            </div>
          </l-tooltip>
          <l-icon
          :icon-size="dynamicSize"
          :icon-anchor="dynamicAnchor"
          icon-url="https://cdn.onlinewebfonts.com/svg/img_370112.png"
          />
        </l-marker>
      </div>
    </l-map>
    </div>
</template>
<script>
import { latLng,icon  } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon  } from "vue2-leaflet";
export default {
data() {
    return {
      zoom: 7,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      staticAnchor: [16, 37],
      iconSize: 30

    }
},
components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon
},
computed:{
    operations(){
      return this.$store.getters['contractor/getOperations'];
    }, 
    mapCoord(){
      return this.$store.getters['contractor/getCoords'];
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
},
methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick(coord) {
      this.$router.push('/dashboard/operations/'+coord.operationId)
    },
    getOperationsCoordinates(){
      this.$store.dispatch('contractor/loadMap',this.operations);
    },
    getLatObject(coord){
      var objectTemp=new Object();
      if(coord){
        objectTemp.lat=coord.lat;
        objectTemp.lon=coord.lon;
        return objectTemp
      }
      else{
        return latLng(34.0346534, -5.0161926)
      }
    },
    titleSplit(title){
      var stringTitle='';
      if(title && title.length>40)
      {
        for(var i=0;i<5;i++){
        stringTitle=stringTitle+' '.concat(title.split(" ")[i])
        }
        return stringTitle
      }
      else return title            
    }
    
},
 mounted() {
    //this.getOperationsCoordinates(); 
 },

}
</script>
<style>
#mapContainer{
    height: 100%;
}
</style>
