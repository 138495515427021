import Vue from 'vue'
import Vuex from 'vuex'

import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from './getters'

import auth from './modules/auth'
import contractor from './modules/contractor'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clientId: localStorage.getItem('id_token'),
    operationId:null

  },
  actions,
  mutations,
  getters,
  modules: {
    auth: auth,
    contractor: contractor
  }
})
