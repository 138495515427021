<template>
    <div>
        <!--
        <div class="row">
            <stats></stats>
        </div>
        -->
         
        <div class="row headers">
            <div class="col-2 operationId"><p>{{titre1}}</p></div><div class="col-1 priorité"><p>{{titre2}}</p></div><div class="col-2 status"><p>{{titre3}}</p></div><div class="col-3 titreDesktop"><p>{{titre4}}</p></div><div class="col-1 catDesktop"><p>{{titre5}}</p></div><div class="col-2 userDesktop"><p>{{titre6}}</p></div><div class="col-1 createdat"><p>{{titre7}}</p></div>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import Operation from '@/pages/Operations/OperationList'
import stats from '@/pages/Operations/stats'

export default {
    components:{
        Operation,
        stats,
    },
    props: ['titre1', 'titre2', 'titre3', 'titre4', 'titre5','titre6','titre7']
}
</script>
<style scoped>
.createdat{
    padding-right: 0px;
    padding-left: 10px;
    color: gray
}
.headers{
    margin-top: 25px;
    padding-right: 10px;
    padding-left: 20px;
    font-size: 14px;
    color: rgba(88, 82, 82, 0.745);
}
p{
  margin-bottom: 0px;
  font-size:14px; 
}
.status{
    float: left;
}
.h5{
    font-size: 14px;
    border-radius: 5px;
    padding:2px 15px 2px 15px;
}
.priorité{
    padding: 0px;
}
.catDesktop{
    padding-left: 10px;
}
.userDesktop{
    padding: 0px;
    padding-left: 10px;
    text-align: center
}
@media screen and (max-width: 999px){
   .catDesktop,.titreDesktop,.userDesktop,.headers
   {
       display: none;
   }
   
}
</style>
