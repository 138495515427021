import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import Operations from "@/pages/Operations/Operations.vue";
import newOperation from "@/pages/Operations/New.vue";
import updateOperation from "@/pages/Operations/UpdateOperation.vue";
import Calendrier from "@/pages/Calendrier/Calendrier.vue";
import TeamList from "@/pages/Equipe/TeamList.vue";
import CRM from "@/pages/CRM/CRM.vue";
import LandingPage from "@/pages/LandingPage.vue";
import rapports from "@/pages/Rapports.vue";
import administration from "@/layout/dashboard/Administration/administration.vue";
import OperationDetail from "@/pages/Operations/Details.vue";
import kanban from "@/pages/Operations/Kanban.vue"
import list from "@/pages/Operations/List.vue"
import newMember from "@/pages/Equipe/NewMember.vue";
import MyOperations from "@/pages/Operations/MyOperations.vue";
//Authentication views
import signup from "@/components/auth/signup"
import signin from "@/components/auth/signin"
import callback from "@/components/auth/callback"
import Resetpassword from "@/components/auth/password-reset"


const routes = [
  {
    path: '/auth/signup',
    name: 'signup',
    component: signup
  },
  {
    path: '/auth/signin',
    name: 'signin',
    component: signin
  },
  {
    path:'/auth/callback',
    name:'callback',
    component: callback
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/home",
    children: [
      {
        path: "/dashboard/operations",
        name: "Opérations",
        component: Operations,
        children:[
          {
            path:'/dashboard/operations',
            name:'Liste des opérations',
            component:list
          },
          {
            path:'/dashboard/operations',
            name:'kanban',
            component:kanban
          }
        ]
      },
      {
        path: "home",
        name: "Accueil",
        component: Dashboard
      },
      {
        path: '/dashboard/profile',
        name: 'profil',
        component: UserProfile
      },
      {
        path: '/dashboard/profile/reset-password',
        name: 'resetpassword',
        component: Resetpassword
      },
     
      {
        path: "/dashboard/calendrier",
        name: "Calendrier",
        component: Calendrier
      },
      {
        path: "/dashboard/crm",
        name: "Carnet clients",
        component: CRM
      },
      {
        path: "/dashboard/equipe",
        name: "Equipe",
        component: TeamList
      },
      {
        path: "/dashboard/equipe/new",
        name: "Equipe > Nouveau membre",
        component: newMember
      },
      {
        path: "/dashboard/rapports",
        name: "Rapports",
        component: rapports
      },
      {
        path: "/dashboard/landingPage",
        name: "Vitrine",
        component: LandingPage
      },
      {
        path: "/dashboard/myOperations",
        name: "Mes opérations",
        component: MyOperations
      },
      {
        path: "/dashboard/administration",
        name: "administration",
        component: administration
      },
      {
        path:'/dashboard/operations/new',
        name:'Nouvelle operation',
        component:newOperation
      },
      {
        path:'/dashboard/operations/update/:id',
        name:"Modification de l'opération",
        component:updateOperation
      },
      {
        path:'/dashboard/operations/:id',
        name:"Détails de l'opération",
        component: OperationDetail
      }
    ]
  },
  { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
