export default {

    Categories:[
            {
                ranking:1,
                name:"Courant fort"
            },
            {
                ranking:2,
                name: "Courant faible"
            }
    ],
    Priorities:[
        {
                ranking:1,
                name:"Faible",
                color:"gray",
        },
        {
                ranking:2,
                name:"Normale",
                color:"#eebb4d"
        },
        {
                ranking:3,
                name:"Elevée",
                color:"red"
        },
        ],
    Status:[
        {
                ranking:0,
                name:"Nouveau",
                color:"#5976ac",
        },
        {
                ranking:1,
                name:"Préparation",
                color:"#c855e4"
        },
        {
                ranking:2,
                name:"Prête",
                color:"#ea86a7"
        },
        {
                ranking:3,
                name:"Planifiée",
                color:"#eebb4d"
        },
        {
                ranking:4,
                name:"En cours",
                color:"#eebb4d",
        },
        {
                ranking:5,
                name:"Terminée",
                color:"#30bfb2"
        },
        {
                ranking:6,
                name:"Facturée",
                color:"#2b987a"
        },
        {
                ranking:7,
                name:"A régulariser",
                color:"#eb5e28"
        },
        {
                ranking:8,
                name:"Régul en cours",
                color:"#fa5c50"
        },
        {
                ranking:9,
                name:"Annulée",
                color:"gray"
        }
        ]
}