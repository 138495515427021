<template>
    <div class="col-12 filter-card">
        <el-button v-if="!filterDisplayed" round v-on:click="displayFilter()">Filtrer</el-button>
        <card v-if="filterDisplayed" class="filter">
        <div class="row">
        <div class="col-md-3 col-12">
            <el-select v-model="statusFilter" placeholder="Statut" :multiple="true" @change="filterList()">
                <el-option :value="empty">Tous</el-option>
                <el-option
                v-for="item in statuses"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                >
                </el-option>
            </el-select>
        </div>
        <div class="col-md-3 col-12">
            <el-select v-model="categoryFilter" placeholder="Catégorie" @change="filterList()">
                <el-option :value="empty">Tous</el-option>
                <el-option
                v-for="item in categories"
                :key="item.name"
                :label="item.name"
                :value="item.name">
                </el-option>
            </el-select>
        </div>
        <div class="col-md-3 col-12">
            <el-select v-model="assigneeFilter" placeholder="Intervenant" @change="filterList()">
                <el-option :value="empty">Tous</el-option>
                <el-option
                v-for="item in assignees"
                :key="item.id"
                :label="item.name"
                :value="item.name">
                </el-option>
            </el-select>
        </div>
        <div class="col-md-2 col-6">
            <el-button round v-on:click="reset()">Rénitisaliser</el-button>
        </div>
        <div class="col-md-1 col-6">
            <el-button circle icon="el-icon-close" v-on:click="hideFilter()"></el-button>
        </div>
        </div>
    </card>
    <el-button round v-on:click="navigateTo('/dashboard/myOperations')">Mes Opérations</el-button>               
    </div>  
</template>
<script>
import config from '@/api/config.js'
import EventBus from '@/eventBus'
    
    export default {
    name: 'operations-filter',
    data: function() {
            return {
                statusFilter: [],
                categoryFilter: '',
                assigneeFilter: '',
                filterDisplayed: false,
                empty:''
        }
    },
    computed:{ 
        statuses(){
            return config.Status
        },
        categories(){
            return config.Categories
        },
        assignees() {
            return this.$store.getters['contractor/getTeamMembers'];
        }
    },
    methods:{
        filterList(){
        let options = {
            limit: 10,
            start: 0,
            status:this.statusFilter,
            category:this.categoryFilter,
            firstName:this.assigneeFilter.split(" ")[0],
            lastName:this.assigneeFilter.split(" ")[1]
        }   
        this.$store.dispatch('contractor/loadOperations',options); 
        EventBus.$emit('ADD_FILTER', options);
        },
        reset(){
            this.assigneeFilter=''
            this.categoryFilter=''
            this.statusFilter=['Nouveau','Prête',"Préparation","En cours","Planifiée"]
            let options = {
            limit: 20,
            start: 0,
            status:this.statusFilter,
            category:this.categoryFilter,
            firstName:this.assigneeFilter,
            lastName:this.assigneeFilter
            }   
            this.$store.dispatch('contractor/loadOperations',options);  
            this.$store.dispatch('contractor/disableAllloaded');
            EventBus.$emit('ADD_FILTER', options);       
        },
        hideFilter(){
            this.filterDisplayed=false;
        },
        displayFilter(){
            this.filterDisplayed=true
        },
        navigateTo (nav) {
            this.$router.push(nav)
        },
        getOperationsCoordinates(){
            this.$store.dispatch('contractor/loadMap');
        },
    },
    mounted(){
        //this.filterList()
       // this.reset();
    }
}
</script>
<style scoped>
.filter-card{
margin-bottom: 20px;
}
@media screen and (max-width: 999px) {
.row .col-12{
    margin-bottom: 10px;
}  
}
</style>
    