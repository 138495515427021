import   
{
GET_OPERATIONS_SUCCESS,
GET_OPERATIONS_FAILURE,
SET_OPERATION_ID,
SET_OPERATION_BY_CONTRACTOR_SUCCESS,
SET_OPERATION_BY_CONTRACTOR_FAILURE,
GET_ONE_OPERATION,
GET_ONE_OPERATION_SUCCESS,
GET_ONE_OPERATION_FAILURE,
SET_STATUS_SUCCESS,
SET_STATUS_FAILURE,
SET_STATUS_COUNT,
GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS,
GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE,
SET_ALL_OPERTATIONS_LOADED,
LOAD_FILTERED_OP,
GET_TEAM_SUCCESS,
GET_TEAM_FAILURE,
ADD_MEMBER_SUCCESS,
ADD_MEMBER_FAILURE,
SET_ASSIGNEE_SUCCESS,
SET_ASSIGNEE_FAILURE,
GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS,
GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE,
SET_ALL_MEMBERS_LOADED,
LOAD_FILTERED_MEMBERS,
LOAD_CLIENTS_SUCCESS,
LOAD_CLIENTS_FAILURE,
LOAD_FILTERED_CLIENTS,
ADD_EVENT_SUCCESS,
ADD_EVENT_FAILURE,
GET_EVENT_SUCCESS,
GET_EVENT_FAILURE,
GET_EVENTS_SUCCESS,
GET_EVENTS_FAILURE,
GET_EVENT_BY_ASSIGNEE_SUCCESS,
GET_EVENT_BY_ASSIGNEE_FAILURE,
GET_TEAM_ARR_SUCCESS,
DELETE_EVENT_SUCCESS,
DELETE_EVENT_FAILURE,
EDIT_EVENT_SUCCESS,
EDIT_EVENT_FAILURE,
ADD_EVENT_ELEMENT_SUCCESS,
ADD_ATTACHMENTS_SUCCESS,
ADD_ATTACHMENTS_FAILURE,
ADD_NOTE_SUCCESS,
ADD_NOTE_FAILURE,
ADD_CONFIG_PRIORITY_SUCCESS,
ADD_CONFIG_PRIORITY_FAILURE,
GET_PRIORITIES_SUCCESS,
GET_PRIORITIES_FAILURE,
DELETE_PRIORITY_SUCCESS,
DELETE_PRIORITY_FAILURE,
ADD_PRIOELEMENT_SUCCESS,
EDIT_PRIORITY_SUCCESS,
EDIT_PRIORITY_FAILURE,
DELETE_STATUS_SUCCESS,
DELETE_STATUS_FAILURE,
EDIT_STATUSELEMENT_SUCCESS,
EDIT_STATUSELEMENT_FAILURE,
ADD_STATUSELEMENT_SUCCESS,
ADD_CONFIG_STATUS_SUCCESS,
ADD_CONFIG_STATUS_FAILURE,
DELETE_CATEGORY_SUCCESS,
DELETE_CATEGORY_FAILURE,
EDIT_CATEGORYELEMENT_SUCCESS,
EDIT_CATEGORYELEMENT_FAILURE,
ADD_CATEGORYELEMENT_SUCCESS,
ADD_CONFIG_CATEGORY_SUCCESS,
ADD_CONFIG_CATEGORY_FAILURE,
SET_NOTE_SUCCESS,
GET_LANDING_SUCCESS,
GET_LANDING_FAILURE,
UPDATE_LANDING_SUCCESS,
UPDATE_LANDING_FAILURE,
UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS,
UPDATE_OPERATION_BY_CONTRACTOR_FAILURE,
LOAD_FILTERED_ASSIGNED_OP,
SET_COORDS_MAP_SUCCESS,
DELETE_OPERATION
}
from '../../mutation-types' 
import Api from '@/api'
import {Notification} from 'element-ui'

export const actions = {
    //////////////////////////////// OPERATIONS MANAGEMENT ////////////////////////

    loadOperations: async function({commit},options){
      try{
        let operations=await Api.getOperations(options);
        commit(GET_OPERATIONS_SUCCESS,operations)
      }     
      catch(e)
      {
        commit(GET_OPERATIONS_FAILURE)
      }
      },

    setOperationId:function({commit}, payload){    
      commit(SET_OPERATION_ID,payload)
    },
    createOperation:async function({commit,state}, payload){  
      // let newOperation=state.newOperation

      try{
        let newOperationData=await Api.setOperation(payload);
        commit(SET_OPERATION_BY_CONTRACTOR_SUCCESS,newOperationData)
        console.log("TRUE")
        Notification.success({
          title: 'Votre opération a été ajoutée avec succès',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        })
      }
      catch(e)
      {
        console.log(e)
        commit(SET_OPERATION_BY_CONTRACTOR_FAILURE)
        console.log("FALSE")
        Notification.error({
          title: "L'ajout de l'opération a échoué",
          timeout: 5000,
          type:'danger',
          icon:'fa fa-times-circle',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        })
      }    
    },
    updateOperation:async function({commit,state}, payload){  
      try{
        let newOperationData=await Api.updateOperation(payload);
        commit(UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS,newOperationData)
        Notification.success({
          title: 'Votre opération a été modifiée avec succès',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e)
      {
        commit(UPDATE_OPERATION_BY_CONTRACTOR_FAILURE)
        Notification.error({
          title: "La modification de l'opération a échoué",
          timeout: 5000,
          type:'danger',
          icon:'fa fa-times-circle',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }    
    },
    deleteOperation: async function({commit},operation)
    {
      try{
        let deleted= await Api.deleteOperation(operation);
        commit(DELETE_OPERATION)
        Notification.success({
          title: 'Votre opération a été supprimée avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        Notification.error({
          title: 'La suppression de votre opération a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    changeStatus: async function({commit},payload)
    {
      try{
        
      let changeStatus=await Api.changeStatus(payload);
      commit(SET_STATUS_SUCCESS,changeStatus)
        
      }
      catch(e)
      {
        commit(SET_STATUS_FAILURE)
      }  

    },
    loadOperationbyId: async function({commit},operationId)
    {
      commit(GET_ONE_OPERATION)
      try {
        let operation = await Api.getOperationById(operationId)
        commit(GET_ONE_OPERATION_SUCCESS, operation)             
      }
      catch (e) {
        commit(GET_ONE_OPERATION_FAILURE)
      }
    },
    refreshOperationbyId: async function({commit},operationId)
    {
      try {
        let operation = await Api.getOperationById(operationId)
        commit(GET_ONE_OPERATION_SUCCESS, operation)             
      }
      catch (e) {
        commit(GET_ONE_OPERATION_FAILURE)
      }
    },
    loadfilteredOperations: async function({commit},filteredOperations)
    {
      commit(LOAD_FILTERED_OP,filteredOperations)
    },
    loadfilteredAssignedOperations: async function({commit},filteredOperations)
    {
      commit(LOAD_FILTERED_ASSIGNED_OP,filteredOperations)
    },
    loadfilteredMembers: async function({commit},filteredMembers)
    {
      commit(LOAD_FILTERED_MEMBERS,filteredMembers)
    },
    setStatusCount:async function({commit})
    {
      let payload={
        allCounts:await Api.getAllCount()
      }
      commit(SET_STATUS_COUNT,payload)
    },
    loadMoreOperations : async function({commit},options) {
      try {
        let operations = await Api.getOperations(options)
        if(operations.length<options.limit && operations.length!=0){
          commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS, operations)   
          commit(SET_ALL_OPERTATIONS_LOADED)
        }
        else {
          commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS, operations)   
        }
        if(operations.length==0){
          commit(SET_ALL_OPERTATIONS_LOADED)
        }
      }
      catch (e) {
          commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE)
      }
    },
    disableAllloaded: function({commit}) {
        commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE)

    },
    loadMoreMembers : async function({commit}) {
      try {
        let members = await Api.getTeamMembers()
        if (members.length > 0) {
          commit(GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS, members)       
        }
        else {
          commit(SET_ALL_MEMBERS_LOADED)
        }
      }
      catch (e) {
        commit(GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE)
      }
    },
    loadTeamList: async function({commit,state}) {
      try{
        let team= await Api.getTeamMembers();
        var arrayMembers=new Array();
        for(var i=0;i<team.accounts.length;i++)
        {
            var objectTemp={id:team.accounts[i].id,name:(team.accounts[i].firstName+' '+team.accounts[i].lastName)}
            arrayMembers.push(objectTemp);
        }
        commit(GET_TEAM_SUCCESS,arrayMembers)
        commit(GET_TEAM_ARR_SUCCESS,team)
      }
      catch(e){
        commit(GET_TEAM_FAILURE)
      }
    },
    addTeamMember: async function({commit,state},newMember) {
      //let newMember=state.newMember
      try{
        let member= await Api.setNewMember(newMember);
        commit(ADD_MEMBER_SUCCESS,member)
        Notification.success({
          position: 'top-right',
          title: 'Succès',
          message: 'Membre ajouté avec succès',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
        })
      }
      catch(e){
        console.log(e)
        commit(ADD_MEMBER_FAILURE)
        Notification.error({
          position: 'top-right',
          title: "Erreur",
          message: "L'ajout du membre a échoué",
          type:'danger',
          timeout: 5000,
          icon:'fa fa-times-circle'
        })
      }
    },
    addAssignee: async function({commit},payload) {
      try{   
        let assignee=await Api.setAssignee(payload);
        commit(SET_ASSIGNEE_SUCCESS,assignee)
        }
        catch(e)
        {
          commit(SET_ASSIGNEE_FAILURE)
        }   
    },
    loadClients: async function({commit}) {
      try{   
        let clients=await Api.getClients();
        commit(LOAD_CLIENTS_SUCCESS,clients)
        }
        catch(e)
        {
          commit(LOAD_CLIENTS_FAILURE)
        }   
    },
    loadfilteredClients: async function({commit},filteredClients)
    {
      commit(LOAD_FILTERED_CLIENTS,filteredClients)
    },
    loadLanding: async function({commit,state}){
      let landingId=state.landingId
      try {
        let landing = await Api.getLandingbyId(landingId)
        commit(GET_LANDING_SUCCESS, landing)             
      }
      catch (e) {
        commit(GET_LANDING_FAILURE)
      }
    },
    updateLanding: async function({commit,state},payload)
    {
      let landingId=state.landingId 
      try {
        let landing = await Api.updateLanding(payload,landingId)
        commit(UPDATE_LANDING_SUCCESS, landing)   
        Notification.success({
          title: 'Votre vitrine a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        })           
      }
      catch (e) {
        commit(UPDATE_LANDING_FAILURE)
        Notification.error({
          title: "La mise à jour de la vitrine a échoué",
          type:'danger',
          icon:'fa fa-times-circle',
          timeout: 5000,
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        })
        
      }
    },
    /////////////////// EVENT MANAGEMENT /////////////////////////
    addNewEvent: async function({commit},payload){
      try{
        let event= await Api.setOperationEvent(payload);
        commit(ADD_EVENT_SUCCESS,event)
        Notification.success({
          title: 'Votre évenement a été bien ajoutée',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        }) 
        
      }
      catch(e){
        console.log(e)
        commit(ADD_EVENT_FAILURE)
        Notification.error({
          title: "L'ajout de l'évenement a échoué",
          type:'danger',
          icon:'fa fa-times-circle',
          timeout: 5000,
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        }) 
      }
    },
    getNewEvent: async function({commit},event){
      try{
        commit(ADD_EVENT_ELEMENT_SUCCESS,event)
      }
      catch(e){
        console.log(e)
      }
    },
    loadEventbyAssignee: async function({commit},assigneeId){
      try{
        let event= await Api.getEventbyAssignee(assigneeId);
        commit(GET_EVENT_BY_ASSIGNEE_SUCCESS,event)
      }
      catch(e){
        console.log(e)
        commit(GET_EVENT_BY_ASSIGNEE_FAILURE)
      }
    },
    loadEventbyOperation: async function({commit},operationId){
      try{
        let event= await Api.getEventbyOperation(operationId);
        commit(GET_EVENT_SUCCESS,event)
      }
      catch(e){
        console.log(e)
        commit(GET_EVENT_FAILURE)
      }
    },
    loadEvents: async function({commit,state},options){
      let organizationId=state.organizationId
      let payload={
        id:organizationId,
        options
      }
      console.log(payload)
      try{
        let events= await Api.getAllEvents(payload);
        commit(GET_EVENTS_SUCCESS,events)
      }
      catch(e){
        console.log(e)
        commit(GET_EVENTS_FAILURE)
      }
    },
    deleteEvent: async function({commit},event){
      try{
        let event1= await Api.deleteOperationEvent(event);
        commit(DELETE_EVENT_SUCCESS)
      }
      catch(e){
        console.log(e)
        commit(DELETE_EVENT_FAILURE)
      }
    },
    editNewEvent :async function({commit},payload){
      try{
        let event= await Api.editOperationEvent(payload);
        commit(EDIT_EVENT_SUCCESS,event)
      }
      catch(e){
        console.log(e)
        commit(EDIT_EVENT_FAILURE)
      }
    },
    addAttachments: async function({commit},payload){
      try{
        let attachments= await Api.setOperationAttachments(payload);
        commit(ADD_ATTACHMENTS_SUCCESS,attachments)
        Notification.success({
          title: 'Votre pièce jointe a été ajoutée avec succès!',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        })
      }
      catch(e){
        console.log(e)
        commit(ADD_ATTACHMENTS_FAILURE)
        Notification.error({
          title: "L'ajout de la pièce jointe a échoué!",
          timeout: 5000,
          type:'danger',
          icon:'fa fa-times-circle',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    addNote: async function({commit},payload){
      try{
        let notes= await Api.setOperationNotes(payload);
        commit(ADD_NOTE_SUCCESS,notes)
        Notification.success({
          title: 'Votre note a été bien ajoutée',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        }) 
      }
      catch(e){
        console.log(e)
        commit(ADD_NOTE_FAILURE)
        Notification.error({
          title: "L'ajout de la note a échoué",
          type:'danger',
          icon:'fa fa-times-circle',
          timeout: 5000,
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        }) 
      }
    },
    editNote: async function({commit},payload){
      try{
        let notes= await Api.editOperationNotes(payload);
        Notification.success({
          title: 'Votre note a été bien modifiée',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        })  
      }
      catch(e){
        Notification.error({
          title: "La modification de la note a échoué",
          timeout: 5000,
          type:'danger',
          icon:'fa fa-times-circle',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
        }) 
        console.log(e)
      }
    },
    getNote: async function({commit},note){
        commit(SET_NOTE_SUCCESS,note)
    },
    addPriority: async function({commit},payload){
      try{
        let priority= await Api.setPriority(payload);
        commit(ADD_CONFIG_PRIORITY_SUCCESS,priority)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(ADD_CONFIG_PRIORITY_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    loadConfig: async function({commit,state}){
      let configId=state.configurationId
      try{
        let config= await Api.getConfiguration(configId);
        commit(GET_PRIORITIES_SUCCESS,config)
      }
      catch(e){
        console.log(e)
        commit(GET_PRIORITIES_FAILURE)
      }
    },
    deletePriority: async function({commit},priority){
      try{
        let priority1= await Api.deletePriority(priority);
        commit(DELETE_PRIORITY_SUCCESS)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(DELETE_PRIORITY_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    getPriorityElement: async function({commit},priorityElement){
      try{
        commit(ADD_PRIOELEMENT_SUCCESS,priorityElement)
      }
      catch(e){
        console.log(e)
      }
    },
    editPriority :async function({commit},payload){
      try{
        let priority= await Api.editPriority(payload);
        commit(EDIT_PRIORITY_SUCCESS,priority)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(EDIT_PRIORITY_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    deleteStatus: async function({commit},status){
      try{
        let status1= await Api.deleteStatus(status);
        commit(DELETE_STATUS_SUCCESS)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(DELETE_STATUS_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    getStatusElement: async function({commit},statusElement){
      try{
        commit(ADD_STATUSELEMENT_SUCCESS,statusElement)
      }
      catch(e){
        console.log(e)
      }
    },
    editStatus :async function({commit},payload){
      try{
        let status= await Api.editStatus(payload);
        commit(EDIT_STATUSELEMENT_SUCCESS,status)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(EDIT_STATUSELEMENT_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    addStatus: async function({commit},payload){
      try{
        let status= await Api.setStatus(payload);
        commit(ADD_CONFIG_STATUS_SUCCESS,status)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(ADD_CONFIG_STATUS_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    deleteCategory: async function({commit},category){
      try{
        let category1= await Api.deleteCategory(category);
        commit(DELETE_CATEGORY_SUCCESS)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(DELETE_CATEGORY_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    getCategoryElement: async function({commit},statusElement){
      try{
        commit(ADD_CATEGORYELEMENT_SUCCESS,statusElement)
      }
      catch(e){
        console.log(e)
      }
    },
    editCategory :async function({commit},payload){
      try{
        let status= await Api.editCategory(payload);
        commit(EDIT_CATEGORYELEMENT_SUCCESS,status)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(EDIT_CATEGORYELEMENT_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    addCategory: async function({commit},payload){
      try{
        let status= await Api.setCategory(payload);
        commit(ADD_CONFIG_CATEGORY_SUCCESS,status)
        Notification.success({
          title: 'Votre configuration a été mise à jour avec succès!',
          type:'success',
          timeout: 5000,
          icon: "ti-check",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
      catch(e){
        console.log(e)
        commit(ADD_CONFIG_CATEGORY_FAILURE)
        Notification.error({
          title: 'La mise à jour de votre configuration a échoué',
          type:'danger',
          timeout: 5000,
          icon: "fa fa-times-circle",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          position:'top right'
      })
      }
    },
    loadMap: async function({commit,state}){
      try{
        let mapCoords= await Api.addMap(state.operations);
        commit(SET_COORDS_MAP_SUCCESS,mapCoords)
      }
      catch(e){
        console.log(e)
      }
    },
    downloadAllOperations: async function({commit,state}) {
      try{
        await Api.downloadAllOperations();
        //commit(GET_OPERATIONS_SUCCESS,operations)
      }
      catch(e){
        console.log(e)
      }
    }
    
    
}
