<template>
    <modal name="event-modal" class="eventModal" :clickToClose='false'>
        <card class="card" >     
            <form @submit.prevent="add()">    
                <div class="row">
                    <a class="button col-12" @click="hide">
                        <h6 class="xbutton ti-close"></h6> 
                    </a>
                    <div class="col-12">
                        <label >Titre</label>
                        <input type="text"
                        placeholder="Titre"
                        v-model="newEvent.summary"
                        name="titre"
                        class="form-control" 
                        :disabled='reuse'
                        />
                    </div>
                    <div class="col-12">
                        <el-switch
                            v-model="reuse">
                        </el-switch>
                        <label for="reuse"> Utiliser le titre de l'opération</label>
                    </div>
                </div>
                <div class="row dates">
                    <div class="col-6">
                        <el-date-picker v-model="newEvent.startDate" type="datetime" placeholder="Pick a day" required>
                        </el-date-picker> 
                    </div>      
                    <div class="col-6">
                        <el-date-picker v-model="newEvent.endDate" type="datetime" placeholder="Pick a day" required>
                        </el-date-picker> 
                    </div>        
                </div>
                <div class="text-center">              
                    <p-button v-if="add1" type="primary" round :loading='submitted'>Ajouter</p-button>
                </div>
            </form>
            <form @submit.prevent="editEvent()">
                <div class="text-center">   
                    <p-button v-if="edit1 || !add1" type="primary" round :loading='submitted'>Modifier</p-button> 
                </div>
            </form>
    </card>
    </modal>
</template>
<script>
import EventBus from '@/eventBus'
import VueMeetingSelector from 'vue-meeting-selector';
import {Notification} from 'element-ui'

export default {
    data: function() {
            return {
                submitted:false,
                switch:false,
                reuse:false,
                empty:{},
                date: new Date('2020-01-01:01:00'),
                loading: false,
                meetingsDays: [],
            }
    },
    components:{
    VueMeetingSelector
    },
    props:{add1:Boolean,edit1:Boolean},
    computed:{
        operationID () {
        return this.$route.params.id
        },
        operation () {
        return this.$store.getters['contractor/getCurrentOperation']
        },
        currentEvent()
        {
        return this.$store.getters['contractor/getCurrentEvent'];        
        },
        newEvent()
        {
        return this.$store.getters['contractor/getNewEvent'];        
        },
        eventUploadSuccess(){
        return this.$store.getters['contractor/getUploadStatus'];           
        },
        assigneeEvents(){
        return this.$store.getters['contractor/getAssigneeEvent'];           
        },
        difference(){
        return this.newEvent.endDate-this.newEvent.startDate
        }
        
        
    },
    methods:{
        show () {
            this.$modal.show('event-modal');
            EventBus.$emit('ADD_EVENT', this.switch);
        },
        hide () {
            this.$modal.hide('event-modal');  
            EventBus.$emit('ADD_EVENT', this.switch)
            this.$store.dispatch('contractor/refreshOperationbyId',this.operationID);
            this.$store.dispatch('contractor/getNewEvent',this.empty)
        },
        async add(){
        let payload;
        if(!this.reuse){
            if(this.operation.assignee){
                payload={
                id : this.operationID,
                startDate : this.newEvent.startDate,
                endDate : this.newEvent.endDate,
                title: this.newEvent.summary,
                assignee: this.operation.assignee.id,
                summary:this.newEvent.summary
                }
            }
            else{
                payload={
                id : this.operationID,
                startDate : this.newEvent.startDate,
                endDate : this.newEvent.endDate,
                title: this.newEvent.summary,
                summary:this.newEvent.summary   
                }    
            }
        }
        else{
           if(this.operation.assignee){
                payload={
                id : this.operationID,
                startDate : this.newEvent.startDate,
                endDate : this.newEvent.endDate,
                title: this.operation.title,
                assignee: this.operation.assignee.id,
                summary:this.newEvent.summary
                }
            }
            else{
                payload={
                id : this.operationID,
                startDate : this.newEvent.startDate,
                endDate : this.newEvent.endDate,
                title: this.operation.title,
                summary:this.newEvent.summary   
                }    
            } 
        }
        if(this.newEvent.startDate && this.newEvent.endDate && this.difference>0)
        {
        this.submitted=true;
        await this.$store.dispatch('contractor/addNewEvent',payload)
        this.submitted=false;
        this.hide()
        this.$store.dispatch('contractor/loadEvents');
        this.reuse=false;
        }
        else{
        Notification.error({
            title: 'Veuillez vérifier les dates que vous avez saisi!',
            type:'danger',
            timeout: 5000,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            position:'top-right',
            icon:'fa fa-times-circle'
        });
        }
        
        },
        intervalFormat(interval) {
        return interval.time
        },
        async editEvent(){
        this.submitted=true;
        let payload={
          id : this.operationID,
          startDate : this.newEvent.startDate,
          endDate : this.newEvent.endDate,
          title: this.newEvent.summary,
          assignee: this.operation.assignee ,
          summary:this.newEvent.summary,
          idEvent:this.newEvent.id
        }
        await this.$store.dispatch('contractor/editNewEvent',payload) 
        this.submitted=false  
        let vm=this;
        setTimeout(function(){vm.hide()}, 1000)
        this.$store.dispatch('contractor/loadEvents');
        }
    },
    mounted () {
        this.$modal.show('event-modal')
    },
    async created(){
        let that=this;
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.hide();
            }
        });
    }
}
</script>
<style scoped>
.dates{
    margin-bottom: 20px;
    margin-top: 20px;
}
.form-control{
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px;
}
.vm--container::v-deep .vm--modal{
    left: 500px !important;
    height:60% !important;
    
}
@media screen and (max-width: 1100px) {
  .vm--container::v-deep .vm--modal{
    width: 100% !important;
    left: 0px !important;
    top: 800px !important
}
.vm--container::v-deep .vm--overlay{
   top: 700px !important
}
}
@media screen and (max-width: 450px) {
  .calendar > input{
    width: 120px;
    padding-left: 10px;
    padding-right: 5px
}
}
</style>
<style lang="postcss" scoped>
/deep/ .vc-pane-container .vc-weeks{
  width: 280px;
  height: 280px;
  padding: 0px;
}
/deep/ .vc-grid-container .vc-grid-cell-row-1{
  padding: 2px !important;
}
</style>
