import   
{
GET_OPERATIONS_SUCCESS,
GET_OPERATIONS_FAILURE,
SET_OPERATION_ID,
SET_OPERATION_BY_CONTRACTOR_SUCCESS,
SET_OPERATION_BY_CONTRACTOR_FAILURE,
GET_ONE_OPERATION,
GET_ONE_OPERATION_SUCCESS,
GET_ONE_OPERATION_FAILURE,
SET_STATUS_SUCCESS,
SET_STATUS_FAILURE,
SET_STATUS_COUNT,
GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS,
GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE,
SET_ALL_OPERTATIONS_LOADED,
LOAD_FILTERED_OP,
GET_TEAM_SUCCESS,
GET_TEAM_FAILURE,
ADD_MEMBER_SUCCESS,
ADD_MEMBER_FAILURE,
SET_ASSIGNEE_SUCCESS,
SET_ASSIGNEE_FAILURE,
GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS,
GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE,
SET_ALL_MEMBERS_LOADED,
LOAD_FILTERED_MEMBERS,
LOAD_CLIENTS_SUCCESS,
LOAD_CLIENTS_FAILURE,
LOAD_FILTERED_CLIENTS,
ADD_EVENT_SUCCESS,
ADD_EVENT_FAILURE,
GET_EVENT_SUCCESS,
GET_EVENT_FAILURE,
GET_EVENT_BY_ASSIGNEE_SUCCESS,
GET_EVENT_BY_ASSIGNEE_FAILURE,
GET_TEAM_ARR_SUCCESS,
DELETE_EVENT_SUCCESS,
DELETE_EVENT_FAILURE,
EDIT_EVENT_SUCCESS,
EDIT_EVENT_FAILURE,
ADD_ATTACHMENTS_SUCCESS,
ADD_ATTACHMENTS_FAILURE,
GET_EVENTS_SUCCESS,
GET_EVENTS_FAILURE,
ADD_NOTE_SUCCESS,
ADD_NOTE_FAILURE,
ADD_CONFIG_PRIORITY_SUCCESS,
ADD_CONFIG_PRIORITY_FAILURE,
GET_PRIORITIES_SUCCESS,
GET_PRIORITIES_FAILURE,
DELETE_PRIORITY_SUCCESS,
DELETE_PRIORITY_FAILURE,
ADD_PRIOELEMENT_SUCCESS,
EDIT_PRIORITY_SUCCESS,
EDIT_PRIORITY_FAILURE,
DELETE_STATUS_SUCCESS,
DELETE_STATUS_FAILURE,
EDIT_STATUSELEMENT_SUCCESS,
EDIT_STATUSELEMENT_FAILURE,
ADD_STATUSELEMENT_SUCCESS,
ADD_CONFIG_STATUS_SUCCESS,
ADD_CONFIG_STATUS_FAILURE,
DELETE_CATEGORY_SUCCESS,
DELETE_CATEGORY_FAILURE,
EDIT_CATEGORYELEMENT_SUCCESS,
EDIT_CATEGORYELEMENT_FAILURE,
ADD_CATEGORYELEMENT_SUCCESS,
ADD_CONFIG_CATEGORY_SUCCESS,
ADD_CONFIG_CATEGORY_FAILURE,
SET_NOTE_SUCCESS,
GET_LANDING_SUCCESS,
GET_LANDING_FAILURE,
UPDATE_LANDING_SUCCESS,
UPDATE_LANDING_FAILURE,
UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS,
UPDATE_OPERATION_BY_CONTRACTOR_FAILURE,
LOAD_FILTERED_ASSIGNED_OP,
ADD_EVENT_ELEMENT_SUCCESS,
SET_COORDS_MAP_SUCCESS,
DELETE_OPERATION
}
from '../../mutation-types' 

export const mutations = {
    [GET_OPERATIONS_SUCCESS]: function (state,payload) {
        state.operations=payload;   
        operationsLoaded=true;
    },
    [GET_OPERATIONS_FAILURE]: function (state) {
        //state.operationsLoaded=false;
    },
    [SET_OPERATION_ID]: function (state,payload) {
        state.operationId=payload;
    },
    [SET_OPERATION_BY_CONTRACTOR_SUCCESS]: function (state,payload) {
        state.operationAdded=true
        state.newOperation=payload;
        state.operations.push(payload)
        state.newOperation={};
    },
    [SET_OPERATION_BY_CONTRACTOR_FAILURE]: function (state) {
        state.operationAdded=false
    },
    [GET_ONE_OPERATION_SUCCESS]: function (state, payload) {
        state.currentOperation = payload
      },
    [GET_ONE_OPERATION_FAILURE]: function (state) {
        //
    },
    [GET_ONE_OPERATION]: function (state) {
        state.currentOperation = {}
    },
    [SET_STATUS_SUCCESS]: function (state) {
        state.statusChanged=true
    },
    [SET_STATUS_FAILURE]: function (state) {
        state.statusChanged=false
    },
    [SET_STATUS_COUNT]: function (state,payload) {
        for(var i=payload.allCounts.length-1;i>=0;i--){
        state.statusCounts.splice(
            0,
            0,
            payload.allCounts[i]
        )
        }
    },
    [GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
        state.operations = state.operations.concat(payload)
    },
    [GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE]: function (state) {
       // state.operationsLoaded = false
    },
    [SET_ALL_OPERTATIONS_LOADED]: function (state) {
        state.operationsLoaded = true;
    },
    [GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
        state.teamMembers = state.teamMembers.concat(payload)
    },
    [GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE]: function (state) {
        // state.operations = payload
    },
    [SET_ALL_MEMBERS_LOADED]: function (state) {
        state.teamLoaded = true
    },
    [LOAD_FILTERED_OP]: function (state,filteredOperations) {
        state.filteredOperations = filteredOperations
    },
    [LOAD_FILTERED_MEMBERS]: function (state,filteredMembers) {
        state.filteredMembers = filteredMembers
    },
    [LOAD_FILTERED_ASSIGNED_OP]: function (state,filteredOperations) {
        state.filteredassignedOperations = filteredOperations
    },
    [GET_TEAM_SUCCESS]: function (state,team) {
        state.teamMembers = team
    },
    [GET_TEAM_ARR_SUCCESS]:function(state,team)
    {
        state.teamMembers2=team.accounts
        state.organizationId=team.id
        state.configurationId=team.configuration.id
        state.landingId=team.landing.id
    },
    [GET_TEAM_FAILURE]: function (state) {
    //
    },
    [ADD_MEMBER_SUCCESS]: function (state,payload) {
        state.memberAdded=true;
        state.newMember=payload;
        state.teamMembers.push(state.newMember);
        state.newMember={};
    },
    [ADD_MEMBER_FAILURE]: function (state) {
        state.memberAdded=false;
    },
    [SET_ASSIGNEE_SUCCESS]: function (state) {
        state.operationAssigned=true;
    },
    [SET_ASSIGNEE_FAILURE]: function (state) {
        state.operationAssigned=false;
    },
    [LOAD_CLIENTS_SUCCESS]: function (state,clients) {
        state.clients=clients;
    },
    [LOAD_CLIENTS_FAILURE]: function (state) {
    //    
    },
    [LOAD_FILTERED_CLIENTS]: function (state,filteredClients) {
    state.filteredClients=filteredClients
    },
    [ADD_EVENT_SUCCESS]: function (state,payload) {
        state.newEvent=payload;
        state.eventUploaded=true;
        state.newEvent={}
    },
    [ADD_EVENT_ELEMENT_SUCCESS]: function (state,payload) {
        state.newEvent=payload;
    },
    [ADD_EVENT_FAILURE]: function (state) {
        state.eventUploaded=false    
    },
    [GET_EVENT_SUCCESS]: function (state,event) {
        state.currentEvent=event;
    },
    [GET_EVENT_FAILURE]: function (state) {
    //    
    },
    [GET_EVENTS_SUCCESS]: function (state,events) {
        state.allEvents=events;
    },
    [GET_EVENTS_FAILURE]: function (state) {
    //    
    },
    [GET_EVENT_BY_ASSIGNEE_SUCCESS]:function(state,event)
    {
        state.assigneeEvent=event
    },
    [GET_EVENT_BY_ASSIGNEE_FAILURE]:function(state)
    {
    //
    },
    [DELETE_EVENT_SUCCESS]:function(state)
    {
    //    state.eventDeleted=true
    },
    [DELETE_EVENT_FAILURE]:function(state)
    {
    //
    },
    [EDIT_EVENT_SUCCESS]:function(state)
    {
    //    state.eventUpdated=true,
        state.newEvent={}
    },
    [EDIT_EVENT_FAILURE]:function(state)
    {
    //
    },
    [ADD_ATTACHMENTS_SUCCESS]:function(state,attachments)
    {
        state.media=attachments.media
        state.attachmentUploaded=true
        state.media={}
    },
    [ADD_ATTACHMENTS_FAILURE]:function(state)
    {
    //   
    },
    [ADD_NOTE_SUCCESS]:function(state,payload)
    {
        state.notes=payload
    },
    [ADD_NOTE_FAILURE]:function(state)
    {
    //   
    },
    [SET_NOTE_SUCCESS]:function(state,note)
    {
        state.note=note   
    },
    [ADD_CONFIG_PRIORITY_SUCCESS]:function(state,payload)
    {
        state.ElementPriority=payload
        state.ElementPriority={}
    },
    [ADD_CONFIG_PRIORITY_FAILURE]:function(state)
    {
    //   
    },
    [GET_PRIORITIES_SUCCESS]:function(state,payload)
    {
        state.priorities=payload.priorities
        state.statuses=payload.statuses
        state.categories=payload.categories
    },
    [GET_PRIORITIES_FAILURE]:function(state)
    {
    //   
    },
    [DELETE_PRIORITY_SUCCESS]:function(state,payload)
    {
    //    
    },
    [DELETE_PRIORITY_FAILURE]:function(state)
    {
    //   
    },
    [ADD_PRIOELEMENT_SUCCESS]:function(state,payload)
    {
    state.ElementPriority=payload
    },
    [EDIT_PRIORITY_SUCCESS]:function(state)
    {
    state.ElementPriority={}
    },
    [EDIT_PRIORITY_FAILURE]:function(state,payload)
    {
    //
    },
    [DELETE_STATUS_SUCCESS]:function(state)
    {
    //
    },
    [DELETE_STATUS_FAILURE]:function(state,payload)
    {
    //
    },
    [ADD_STATUSELEMENT_SUCCESS]:function(state,payload)
    {
    state.ElementStatus=payload
    },
    [EDIT_STATUSELEMENT_SUCCESS]:function(state)
    {
    state.ElementStatus={}
    },
    [EDIT_STATUSELEMENT_FAILURE]:function(state,payload)
    {
    //
    },
    [ADD_CONFIG_STATUS_SUCCESS]:function(state,payload)
    {
        state.ElementStatus=payload
        state.ElementStatus={}
    },
    [ADD_CONFIG_STATUS_FAILURE]:function(state)
    {
    //   
    },
    [DELETE_CATEGORY_SUCCESS]:function(state)
    {
    //
    },
    [DELETE_CATEGORY_FAILURE]:function(state,payload)
    {
    //
    },
    [ADD_CATEGORYELEMENT_SUCCESS]:function(state,payload)
    {
    state.ElementCategory=payload
    },
    [EDIT_CATEGORYELEMENT_SUCCESS]:function(state)
    {
    state.ElementCategory={}
    },
    [EDIT_CATEGORYELEMENT_FAILURE]:function(state,payload)
    {
    //
    },
    [ADD_CONFIG_CATEGORY_SUCCESS]:function(state,payload)
    {
        state.ElementCategory=payload
        state.ElementCategory={}
    },
    [ADD_CONFIG_CATEGORY_FAILURE]:function(state)
    {
    //   
    },
    [GET_LANDING_SUCCESS]: function (state,payload) {
      state.landing=payload;
    },
    [GET_LANDING_FAILURE]: function (state,payload) {
      //
    },
    [UPDATE_LANDING_SUCCESS]: function (state,payload) {
      state.landing=payload;
      state.landingUpdated=true;
    },
    [UPDATE_LANDING_FAILURE]: function (state,payload) {
      //
    },
    [UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS]: function (state,payload) {
    state.newOperation=payload
    },
    [UPDATE_OPERATION_BY_CONTRACTOR_FAILURE]: function (state,payload) {
    //
    },
    [SET_COORDS_MAP_SUCCESS]: function (state,payload) {
    state.mapCoords=payload
    },
    [DELETE_OPERATION]: function (state,payload) {
        state.newOperation={}
    },
}
