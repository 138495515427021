<template>
  <component
    :is="tag"
    :type="nativeType"
    :disabled="disabled || loading"
    class="btn"
    :class="[
      {'btn-round': round},
      {'btn-block': block},
      {'btn-just-icon': icon},
      {[`btn-${type}`]: type && !outline},
      {[`btn-outline-${type}`]: type && outline},
      {[`btn-${size}`]: size},
      {'btn-link': simple}
    ]">
    <slot name="loading">
      <i v-if="loading" class="fa fa-spinner fa-spin"></i>
    </slot>
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'p-button',
  props: {
    tag: {
      type: String,
      default: "button"
    },
    round: Boolean,
    icon: Boolean,
    outline: Boolean,
    block: Boolean,
    loading: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: "default"
    },
    nativeType: {
      type: String,
      default: "submit"
    },
    size: {
      type: String,
      default: ""
    },
    simple: Boolean
  }
};
</script>
<style scoped>
/*
.btn
{
    background-color: #5FD5b7 !important;
    color: white;
    width: 60% !important;
    border-color: #5FD5b7 !important;
    font-size: 13px !important;
    margin:auto !important;
    padding: 11px 10px;
    opacity: 1 !important;
}
.btn:hover
{
    border-color: #f1f3f4;
    background-color: #5FD5b7;
    opacity: 0.9 !important;
}
*/
</style>
