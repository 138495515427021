<template>
    <div class="row">
        <div class="col leftside">
         <sidebar></sidebar>       
        </div>  
        <div class="col rightside">
            <div class="vue-tempalte text-center ">
                <h2  class="head1 text-center">Connexion</h2>
                <img class="head2 logo" src="@/assets/img/proximov-logo-gray.png" alt="">
                <card class="card text-center" >
                    <form @submit.prevent="login">
                        <div class="form-group position-relative">
                                <input type="text"
                                placeholder="Email"
                                v-model="email"
                                name="email"
                                class="form-control-lg">
                            <i class="fa fa-envelope-o fa-lg position-absolute"></i>
                        </div>
                        <div class="form-group position-relative">
                            <input type="password" id="pass" placeholder="Mot de passe" v-model="password" name="password" class="form-control-lg"  required>
                            <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password" @click="showPassword"></span>
                            <i class="fa fa-key fa-lg position-absolute"></i>
                        </div>
                        <p-button type="primary" :round="true" :loading='submitted' v-on:click='loadingOn'>Se connecter</p-button>
                        <p v-if="displayAuthFailureMessage" class="auth-failure-msg">L'authentification a échoué, veuillez réessayer!</p>
                        <p class="forgot-password text-center"><router-link :to="{name: 'pwreset'}"> Mot de passe oublié? </router-link> </p>
                    </form>
                </card>
                <p class="forgot-password text-center">
                        Je n'ai pas encore un compte, 
                        <router-link :to="{name: 'signup'}">je m'inscris</router-link>
               </p>
            </div>
        </div>      
    </div>
</template>

<script>
import sidebar from "@/components/auth/sidebarsignin.vue"
    export default {
        name:'signin',
        components:{
            sidebar
        },
        data: function() {
            return {
            email:'',
            nom: '',
            password: '',
            submitted: false,
            mobileView:true
            }
        },
        computed: {
            displayAuthFailureMessage: function () {
            this.submitted=false;
            return this.$store.getters['auth/displayAuthFailureMessage']
        },
            displayAuthSuccessMessage: function () {
            return this.$store.getters['auth/displayAuthSuccessMessage']
        },
        
        },
        methods:
        {
            notif: function() {
                this.$notify({
                title: 'Failed to sign in',
                type:'danger',
                timeout: 5000,
                horizontalAlign: 'right',
                verticalAlign: 'top',
                position:'top-right',
                icon:'fa fa-times-circle'
                });   
            },
            notifsuccess: function() {
                    this.$notify({
                    title: 'Successfuly signed in',
                    messsage: 'Very nicely done',
                    type:'success',
                    timeout: 5000,
                    icon: "ti-check",
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    position:'top-right'
                    })
            },
            async login()
            {
            this.submitted=true;
            let payload = {
            type: 'contractor',
            email : this.email,
            password : this.password
            }
            await this.$store.dispatch('auth/login', payload)
            this.submitted=false;       
            this.submitted=true;
            if(this.displayAuthSuccessMessage == true){
                this.notifsuccess
            }
            else if(this.displayAuthSuccessMessage != true && this.displayAuthFailureMessage){
                this.notif
            }
            },
            showPassword()
            {
            var temp = document.getElementById("pass"); 
            if (temp.type === "password") { 
                temp.type = "text";
            } 
            else { 
                temp.type = "password";
            } 
            },
            loadingOn(){
            this.submitted=true;
            } 
        }   
    }
</script>
<style scoped>
h1{
    font-weight: bold;
    font-size: xx-large;
    padding-bottom: 50px;
}
.logo {
    height : 25px;
    width: auto !important;
    margin-bottom: 20px;
}
.row {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  margin: 0px;
  font-family: Nunito, sans-serif;
  background-color: #f1f3f4;
}
.card{
    background-color: white;
    padding:50px;
    border-radius:10px;    
    width: 400px;
    display: inline-block
}
body{
    background: #f1f3f4;
}
form{
    align-items: center;
    flex-direction: column;
}
.forgot-password
{
    margin-top: 10px;
    font-size: medium;
}
.form-group ,h2
{
    position: relative;
    padding: 5px;      
}
.form-check
{
    margin-bottom: 15px
}
h2{
    font-weight: bold;
    padding: 10px 5px 10px 10px;
}
input:focus
{
    outline: none;
    box-shadow: 0px 0px 2px #2d3748;
    border-radius:10px;    
}
input
{
    background-color: #f1f3f4; 
    font-size: 17px;
}
.form-control-lg{
    background-color: #f1f3f4;
    padding-left: 50px;
    border: none;
    border-radius:10px;    
    width: 250px
}
.form-group i {
  position: absolute;  
  font-family: FontAwesome;
  margin: 0 auto;
  font-size: 1rem;
  font-style: normal;
  left: 20px;
  top: 21px;
  padding: 2px;
  bottom: 5px;
  color: gray;
}
.rightside{
    padding-top: 85px;
    
}
@media screen and (max-width: 900px) {
.card
{
    margin:0px;
    width: 80%;
    padding-right: 10px;
    padding-left: 10px;
}
.field-icon {
display: none;
}
side-bar{
    display: none
}
.btn{
    font-size: 10px;
    width: 50%;
    padding: 10px
}
 
.form-control-lg{
    width: 100%;
}
.form-group i
{
    left: 20px;
    top:18px;
}
}
@media screen and (max-width: 450px) {
.card{
    width: 100%
}
}
@media screen and (max-width: 300px) {
.btn{
    width: 75%;
}
.form-check-label,.forgot-password,.btn
{
    font-size: 11px;
}
}
</style>
