<template>
    <modal name="config-modal" class="eventModal" :scrollable='true' :resizable='true' height="auto" :adaptive="true" :clickToClose='false'>
        <card class="card" >     
            <form @submit.prevent="add()">    
                <div class="row">
                    <a class="button col-12" @click="hide">
                        <h6 class="xbutton ti-close"></h6> 
                    </a>
                    <div class="col-12">
                        <label >Nom</label>
                        <input type="text" placeholder="Nom" v-model="elementCategory.name" name="titre" class="form-control" />
                    </div>
                    <div class="col-12">
                        <label >Rang</label>
                        <input type="text" placeholder="Rang" v-model="elementCategory.ranking" name="Rang" class="form-control" />
                    </div>
                </div>
                <div class="text-center">              
                    <p-button v-if="add3" type="primary" round :loading='submitted'>Ajouter</p-button>
                </div>
            </form>
            <form @submit.prevent="editCategory()">
                <div class="text-center">   
                    <p-button  v-if="edit3 || !add3" type="primary" round :loading='submitted'>Modifier</p-button> 
                </div>
            </form>
    </card>
    </modal>
</template>
<script>
import EventBus from '@/eventBus'

export default {
    data: function() {
        return { 
        submitted: false,
        switch:false
        }
    }, 
    props:{add3:Boolean,edit3:Boolean},
    computed:{
        organizationId() {
        return this.$store.getters['contractor/getOrganizationId'];
        },
        configurationId() {
        return this.$store.getters['contractor/getConfigurationId'];
        },
        elementCategory() {
        return this.$store.getters['contractor/getElementCategory'];
        },
        
    },
    methods:{
        show () {
            this.$modal.show('config-modal');
            EventBus.$emit('ADD_CATEGORY', this.switch); 
        },
        hide () {
            this.$modal.hide('config-modal');
            EventBus.$emit('ADD_CATEGORY', this.switch);
            this.$store.dispatch('contractor/loadConfig')
        },
        close(){
            this.$modal.close('config-modal');
            EventBus.$emit('ADD_CATEGORY', this.switch);
        },
        async add(){
        this.submitted=true;
        let payload={
          id : this.configurationId,
          name: this.elementCategory.name,
          ranking:this.elementCategory.ranking
        }
        await this.$store.dispatch('contractor/addCategory',payload);
        this.submitted=false
        this.hide()
        },
        async editCategory(){
        this.submitted=true;
        let payload={
          id : this.configurationId,
          name: this.elementCategory.name,
          ranking:this.elementCategory.ranking,
          idStatus:this.elementCategory.id
        }
        await this.$store.dispatch('contractor/editCategory',payload) 
        this.submitted=false  
        let vm=this;
        setTimeout(function(){vm.hide()}, 1000)
        }
    },
    mounted () {
        this.$modal.show('config-modal')
    },
    created(){
        let that=this;
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.hide();
                that.close();
            }
        });
    }
}
</script>
<style scoped>
.form-control{
    position: relative;
}
.vm--container::v-deep .vm--modal{
    left: 500px !important;
}
@media screen and (max-width: 1100px) {
  .vm--container::v-deep .vm--modal{
    left: 0px !important;
}
}
</style>
