<template>
    <div class="detailcontainer" >
        <card class="card header">
            <div class="row">
            <p class="col-lg-3 col-md-6 col-6 numero "><font color="#5fd5b7">No</font> {{operation.reference}}</p> <p class="priorité col-lg-3 col-md-6 col-6" v-bind:style="priorityColor(operation.priority)">Priorité {{operation.priority}}</p> 
            <p class="col-lg-3 col-md-6 col-sm-6 col-6 position-relative" v-if="(typeof(operation.assignee) != 'undefined') &&  (typeof(operation.assignee) != 'null') && operation.assignee !=null ">
                        <img :src="operation.assignee.picture" class="userPicture">
                        <dropdownlist
                        :options="getMemberList"
                        v-on:selected="validateSelection2"
                        :disabled="false"
                        name="assigneelist"
                        :maxItem="20"
                        placeholder=""
                        :searchFilter="operation.assignee.firstName+' '+operation.assignee.lastName"
                        class="listAssignee"
                        ><i class="fa fa-edit icon" ></i></dropdownlist>
            </p>    
            <p class="col-lg-3 col-md-6 col-sm-6 col-6" v-if="(typeof(operation.assignee) == 'undefined') ||  (typeof(operation.assignee) == 'null') || operation.assignee ==null">
                        <dropdownlist
                        :options="getMemberList"
                        v-on:selected="validateSelection2"
                        :disabled="false"
                        name="assigneelist"
                        :maxItem="20"
                        placeholder="Not assigned"
                        class="listAssignee"
                        ><i class="fa fa-edit icon" ></i></dropdownlist>
                        
            </p>
            <p class="col-lg-3 col-md-6 col-sm-6 col-6 status">
                        <dropdownlist
                        :options="getSatuses"
                        v-on:selected="validateSelection"
                        :disabled="false"
                        name="statuslist"
                        :maxItem="10"
                        placeholder=""
                        id="mystatus"
                        :searchFilter="operation.status"
                        ><i class="fa fa-edit icon" ></i></dropdownlist>
            </p>
            </div>
        </card>
        <card class="card" >
            <div class="row" v-if="operation != null">
                <div class="col-8"><p class="category">{{operation.category}}</p></div>
                <div class="col-4 actions"> 
                    <a v-on:click="goOperationDetails(operation.id)"><i class="fa fa-edit fa-cta"></i></a>
                </div>
                <div class="col-lg-8 col-12 bloc-top">                    
                            <p class="title">{{operation.title}}</p>
                            <p>{{operation.description}}</p>
                            
                            <div class="col-lg-4"></div>                        
                </div>
                <div class="col-lg-4 col-12">
                    <div v-if="operation.contact">
                                    <h5 class="semi-title">Contact Principal</h5>
                                    <p><span class="ti-user"></span> : {{operation.contact.name}}</p>
                                    <p><span class="ti-headphone"></span> : <a :href="getTelLink(operation.contact.phoneNumber)">{{operation.contact.phoneNumber}}</a></p>
                                    <p><span class="ti-email"></span> : {{operation.contact.email}}</p>
                    </div>
                        <div v-if="operation.secondaryContact">
                                    <h5 class="semi-title">Contact secondaire</h5>
                                    <p><span class="ti-user"></span> : {{operation.secondaryContact.name}}</p>
                                    <p><span class="ti-headphone"></span> : <a :href="getTelLink(operation.secondaryContact.phoneNumber)">{{operation.secondaryContact.phoneNumber}}</a></p>
                                    <p><span class="ti-email"></span> : {{operation.secondaryContact.email}}</p>
                    </div>
                        <div v-if="operation.location">
                                    <h5 class="semi-title">Localisation</h5>
                                    <p> <span class="ti-location-pin"></span> : {{operation.location.address}} - {{operation.location.city}} ({{operation.location.zipCode}}) - {{operation.location.country}}
                                    </p>
                                    <!--
                                    <div class="col-12">{{operation.location.address}}</div>
                                    <div class="col-12">{{operation.location.zipCode}}</div>
                                    <div class="col-12">{{operation.location.city}}</div>
                                    <div class="col-12">{{operation.location.country}}</div>
                                    -->
                                    <p>
                                        <a :href="getWazeLink(operation.location)" target="_blank">Ouvrir avec Waze</a>
                                    </p>
                                    <p>
                                        <a :href="getMapsLink(operation.location)" target="_blank">Ouvrir avec Maps</a>
                                    </p>
                    </div>
                </div> 
                <div class="col-12 row">
                    <p class="creation col-md-4 col-6"> Date de création  {{getHumanDate(operation.createdAt)}}  </p>
                    <p class="creation col-md-4 col-6"> Mise à jour   {{getHumanDate(operation.updatedAt)}} </p>
                </div>
            </div> 
        </card>   
        <div class="row">
            <div class="col-12">
            <el-tabs v-model="activeTab">
                <el-tab-pane label="Planification" name="planification">
                    <event></event> 
                </el-tab-pane>
                <el-tab-pane label="Pièces Jointes" name="attachments">
                                  <div class="col-12" v-if="operation.attachments">
                                <div class="row"><div class="col-lg-12 col-12"> <el-button type="primary" class="add" round v-on:click="switchOnAttachment()">Ajouter une pièce jointe</el-button> </div></div>
                                <card class="row card smallCards" v-for="attachment in operation.attachments" :key="attachment.id">
                                    <div class="titreNormalGras col-12">{{attachment.title}} </div>
                                    <div class="mediaDate col-12">Ajouté le : {{getHumanDate(attachment.createdAt)}}</div> 
                                    <div class="thumbnailWrapper" v-if="attachment.media.length > 0">
                                        <div v-for="media in attachment.media" :key="media.id">    
                                            <a :href="getURL(media.url)"  target="_blank">          
                                                <img v-if="isPicture(media)" class="thumbnail" :src="getURL(media.formats.thumbnail.url)" />
                                                <img v-if="isPDF(media)" class="thumbnail" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/267px-PDF_file_icon.svg.png" />
                                            </a>
                                        </div>
                                    </div> 
                                </card>
                                <div class="popup">
                                    <popup-form-attachment v-if="popupOn">
                                    </popup-form-attachment>
                                </div>
                            </div>
                </el-tab-pane>
                <el-tab-pane label="Compte Rendu" name="notes">
                                <div class="col-12" v-if="operation.notes">
                                    <div class="row"><div class="col-lg-12 col-12"><el-button type="primary" class="add" round v-on:click="switchOnNoteForm()">Ajouter une note</el-button></div></div>
                                <card class="row card smallCards" v-for="note in operation.notes" :key="note.id">
                                    <div class="row"> 
                                        <div class="titreNormalGras col-10">{{note.title}} </div>
                                        <div class="col-2">
                                            <a class="button" @click="edit(note)">
                                                <h6 class="fa fa-edit icon"></h6> 
                                            </a>
                                        </div>
                                        <p class="mediaDate col-12">Ajouté le : {{getHumanDate(note.createdAt)}}</p> 
                                        <div><p>{{note.content}}</p></div>
                                    </div>
                                    
                                </card>
                                <div class="popup">
                                    <popup-note v-if="popupNoteOn" :editnt='editNote' :addnt='popupNoteOn'>
                                    </popup-note>
                                    <popup-note v-if="editNote" :editnt='editNote' :addnt='popupNoteOn'>
                                    </popup-note>
                                </div>
                            </div>
                </el-tab-pane>
            </el-tabs>   
            </div> 
        </div>                              
    </div>
</template>
<script>
import moment from 'moment';
import configColor from '@/api/config.js'
import Dropdownlist from '@/components/Dropdownlist.vue'
import client from '@/pages/CRM/Clients.vue'
import event from '@/pages/Operations/Event.vue'
import PopupFormAttachment from '@/components/Popups/PopupAttachments.vue'
import PopupNote from '@/components/Popups/NotePopup.vue'
import EventBus from '@/eventBus'

export default {
      data: function() {
            return {  
                assignee:'Not assigned',
                searchAssignee:'',
                selectedDate: {
                start: null,
                end: null
                },
                popupOn:false,
                popupNoteOn:false,
                editNote:false,
                activeTab: 'planification'
            }
    },
    components:{
        Dropdownlist,
        event,
        client,
        PopupFormAttachment,
        PopupNote
    },
    computed:{
        operationID () {
        return this.$route.params.id
        },
        operation () {
        return this.$store.getters['contractor/getCurrentOperation']
        },
        getSatuses(){
        return configColor.Status    
        },
        getMemberList()
        {
        return this.$store.getters['contractor/getTeamMembers'];    
        },
        currentEvent()
        {
        return this.operation.events        
        },
        range()
        {
        let range={
            start:this.currentEvent.startDate,
            end:this.currentEvent.endDate
        }
        return range
        }
    },
    methods:{

        getHumanDate : function (date) 
        {
        return moment(date).format('DD/MM/YYYY HH:mm');
        },
        priorityColor(priority)
        {
         for(var i=0;i<configColor.Priorities.length;i++){
            if(configColor.Priorities[i].name==priority)
            {
                return { 
                'color':configColor.Priorities[i].color,
                };
            }
        }
        },
        async validateSelection(status){
        let payload={
            operationId: this.operationID,
            status:status.name,
            }
        if(payload.status && this.operation.status ){
            if(status.name!=this.operation.status){
                await this.$store.dispatch('contractor/changeStatus',payload)          
                this.$store.dispatch('contractor/refreshOperationbyId',this.operationID)
                this.loadOperations();
            }
            else if(status.name==this.operation.status){
                let vm=this;
                setTimeout(function(){vm.$store.dispatch('contractor/refreshOperationbyId',vm.operationID)}, 500)
            }
        }
        },
        async validateSelection2(assignee){
        let payload={
            operationId: this.operationID,
            assignee:assignee.id,}
        let vm=this;
        if(payload.assignee && this.operation.assignee ){
            if(assignee.id!=this.operation.assignee.id){
                await this.$store.dispatch('contractor/addAssignee',payload)
                this.$store.dispatch('contractor/refreshOperationbyId',this.operationID)
                this.loadOperations();
            }
            else{}
        }
        else if(!payload.assignee){}
        else{
            await this.$store.dispatch('contractor/addAssignee',payload)
            this.$store.dispatch('contractor/refreshOperationbyId',this.operationID)
            this.loadOperations();
        }
        },
        getURL(uri){
            return process.env.VUE_APP_API_URI + uri
        },
        isPicture(media){
            if(media.mime=='image/png' || media.mime=='image/jpeg'){
                return true
            }
            else return false
        },
        isPDF(media){
            if(media.mime=='application/pdf'){
                return true
            }
            else return false
        },
        switchOnAttachment()
        {
            this.popupOn=true
        },
        switchOnNoteForm(){
            this.popupNoteOn=true
        },
        edit(note){
            this.editNote=true;
            this.popupNoteOn=false;
            this.$store.dispatch('contractor/getNote',note)
        },
        getTelLink(tel) {
            return 'tel:'+ tel
        },
        getWazeLink(location) {
            return 'https://waze.com/ul?q=' + location.address + ' ' + location.city + ' ' + location.country
        },
        getMapsLink(location) {
            //var apiKey='AIzaSyABpT5RoVDfhiCPCH0ExCoWwxqYtAyRHEU'
            //var req='https://maps.googleapis.com/maps/api/geocode/json?new_forward_geocoder=true&address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key='+apiKey
            return 'http://maps.google.com/?q=' + location.address + ' ' + location.city + ' ' + location.country
        },
        goOperationDetails(id){
            this.$store.dispatch('contractor/setOperationId',id);
            this.$router.push('/dashboard/operations/update/'+id);
        },
        getEventAssignee(){
            if(this.operation.assignee){
            this.$store.dispatch('contractor/loadEventbyAssignee',this.operation.assignee.id);
            }
        },
        loadOperations(){
            let options = {
            limit: 20,
            start: 0,
            category:'',
            status:['Nouveau','Prête',"Préparation","En cours","Planifiée"],
            firstName:'',
            lastName:''
            }   
            this.$store.dispatch('contractor/loadOperations',options); 
            this.$store.dispatch('contractor/setStatusCount')
        },
    },
    created(){ 
        this.getEventAssignee();
        this.$store.dispatch('contractor/loadOperationbyId',this.operationID);
        EventBus.$on('ADD_ATTACHMENT', (addOff) => {
        this.popupOn=addOff;
        })
        EventBus.$on('ADD_NOTE', (addNoteOff) => {
        this.popupNoteOn=addNoteOff;
        })
    },
    mounted(){
        EventBus.$on('ADD_ATTACHMENT', (addOff) => {
        this.popupOn=addOff;
        })
        EventBus.$on('ADD_NOTE', (addNoteOff) => {
        this.popupNoteOn=addNoteOff;
        this.editNote=addNoteOff;
        })
    }
}
</script>
<style scoped>
.semi-title {
    font-size: 16px;
    font-weight: 600;
}
.status .icon{
    color: white !important;
}
.icon{
    visibility: hidden;
    float: right;
    color: #2d3748;
}
.smallCards:hover{
    background-color: #8f919417;
}
.col-lg-5 > .card{
    margin-top: 20px;
}
.popup{
    display: flex;
    align-items: center;
    justify-content: center;
}
.col{
    color:#2d3748;
    text-align: center;
    padding: 0px 5px 10px 5px;
}
.head{
    font-size: 14px;
}
.titre,.titreNormalGras{
    font-size: 18px;
    color:#2d3748;
    font-weight: bold;
    padding: 5px 0 0px 15px;
}
.add{
    float: right;
    margin-bottom: 10px;
}
.datePicker{
    right: 0;
    text-align: right;
}
.header{
    padding-left: 0px;
    padding-right: 0px;
}
.texte,.titre{
    font-size: 18px;
}
.detailcontainer{
    padding:auto;
}
.card{
  border-radius: 10px;  
  border: none;
}
tr {
    display: block;
    width: 96%;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 10px 0;
    padding: 0;
    margin-left: 30px;
}
p {
    margin-bottom: 10px;
}
.priorité{
    color: rgb(54, 54, 54);
    font-size: 16px;
    padding-right: 0px;
}
.row{
    padding-left: 5px;
}
.category{
    color: gray;
    font-size: 20px;
}
.numero{
    font-size: 18px;
    font-weight: bold;
    padding-right: 5px;
}
.title{
    font-weight: bold;
    font-size: 20px;
    color: #2d3748;
}
.creation{
    color:#75797e;
    font-size: 13px;
}
.mediaDate{
    color:#75797e;
    font-size: 13px; 
    padding: 0px 15px;
}
.userPicture{
    position: absolute;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    margin-top: 3px;
    left: 8px;
    z-index: 10;
}
.thumbnailWrapper{
    display:flex;
}
.thumbnail{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin: 10px;
}
.status{
    font-weight: 700;
    text-transform: uppercase;
}
.bloc-top {
    margin-bottom: 25px;
}
.ti-cta {
    font-size: 30px;
}
.fa-cta {
    font-size: 25px;
    color: #5fd5b7;
}
.actions {
    text-align: right;
}
@media screen and(max-width:400px) {
.creation,.p{
    font-size: 14px;
} 
.titre{
    padding-left: 5px;
}
}
</style>
<style lang="scss" scoped>
.smallCards:hover .icon{
    visibility: visible;
    cursor: pointer;
}
</style>