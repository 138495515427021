import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import AuthService from '@/auth/AuthService'


Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode:'history',
  routes,
  linkActiveClass: "active"
  
});
router.beforeEach((to, from, next) => {

  if(!AuthService.checkAuth("contractor") && to.path === '/home'){
    next('/auth/signin')
    window.history.forward();  
  }
  if(AuthService.checkAuth("contractor") && to.path === '/auth/signin'){
    next({
      path: '/home',
    })
  }
  if(AuthService.checkAuth("contractor") && to.path === '/auth/signup'){
    next({
      path: '/home',
    })
  }
  else {
    next() // make sure to always call next()!
  }
})
export default router;
