<template>
<div class="members">
    <div class="row">
            <div class="col-8">
                <fg-input type="text"
                            placeholder="Search"
                            v-model="search"
                            class="searchInput"
                            v-on="filterList"
                            >
                </fg-input>
            </div>
            <div class="col-4 buttons">
                <el-button type="primary" circle icon="el-icon-plus" v-on:click="navigateTo('/dashboard/equipe/new')"></el-button>
            </div>
    </div>
    <div class="row headers">
    <div class="col-2"><p>Profile Picture</p></div><div class="col-3"><p>Email</p></div><div class="col-2"><p>Last Name</p></div><div class="col-2"><p>First Name</p></div><div class="col-3"><p>Role</p></div>
    </div>
    <members></members> 
</div>
</template>
<script>
import members from "@/pages/Equipe/Members.vue"
import list from "@/pages/Operations/List.vue"

export default {
    
    data: function() {
            return {
                search:''
            }
    },
    components:{
        list,
        members
    },
    methods:{  
    navigateTo (nav) {
        this.$router.push(nav)
    }
    },
    computed:{
    members(){
        return this.$store.getters['contractor/getTeamMembers2'];
    },
    filteredMembers() {    
        if(this.search==='')
        {
            return this.members;
        }
        else{
        return this.members.filter( (member) => {
            if(member.firstName && member.lastName && member.email && member.role ){
            return member.email.toLowerCase().includes(this.search.toLowerCase()) || member.firstName.toLowerCase().includes(this.search.toLowerCase()) || member.lastName.toLowerCase().includes(this.search.toLowerCase()) || member.role.toLowerCase().includes(this.search.toLowerCase());
            }
            if(member.firstName || member.lastName || member.email && member.role)
            {
            return member.email.toLowerCase().includes(this.search.toLowerCase()) || member.firstName.toLowerCase().includes(this.search.toLowerCase()) || member.lastName.toLowerCase().includes(this.search.toLowerCase()) || member.role.toLowerCase().includes(this.search.toLowerCase());
            }   
        });
        }
        },
    filterList(){
        this.$store.dispatch('contractor/loadfilteredMembers',this.filteredMembers);     
    }
    }
}
</script>
<style scoped>
.headers{
    margin-top: 25px;
    padding-right: 10px;
    font-size: 14px;
    color: rgba(88, 82, 82, 0.745);
}
.headers p{
    text-align: center;
}
.buttons > button{
    float: right;
}
@media screen and (max-width: 999px){
.headers p{
    display: none;
}   
}
</style>
