import {
    SIGNUP,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    SET_ACCOUNT,
    INIT_AUTH_STATE,
    WEAK_PASSWORD,
    GET_PROFILE_BY_CONTRACTOR_SUCCESS,
    GET_PROFILE_BY_CONTRACTOR_FAILURE,
    SET_PROFILE_BY_CONTRACTOR_SUCCESS,
    SET_PROFILE_BY_CONTRACTOR_FAILURE,
    UPDAYE_PW_SUCCESS,
    UPDAYE_PW_ERROR
  } from '../../mutation-types'
  
  import AuthService from '@/auth/AuthService'
  import Api from '@/api'
  import {Notification} from 'vue-notifyjs'

  export const actions = {
     //Sign up Action 
    signup: async function({commit},payload)
    {   
        try{
            let account=await Api.createAccount(payload);
            commit(SIGNUP_SUCCESS)
            commit(SIGNUP)
            commit(SET_ACCOUNT,account)
            Notification.notify({type: 'success', message: 'Account successfuly created, redirecting to sign in',timeout: 5000,icon: "ti-check"})
            window.location.href = location.origin +"/auth/signin"
            }                 
        catch(e)
          {
            if(e.response.status == '500')
            {
              Notification.notify({type: 'danger', message: 'Failed to sign up',timeout: 5000,icon: "fa fa-times-circle"})
              commit(WEAK_PASSWORD)
            }
            if(e.response.status == '409')
            {
              Notification.notify({type: 'danger', message: 'Failed to sign up',timeout: 5000,icon: "fa fa-times-circle"})
              commit(SIGNUP_FAILURE)
            }
            
          }
              
    },
    /**
     * Login Action 
     */
    login: function ({commit}, payload) {
      commit(LOGIN)
      AuthService.loginWithKeycloak(payload.type, payload.email, payload.password, 
      function(err){   
      if(err){     
      commit(LOGIN_FAILURE)
      Notification.notify({type: 'danger', message: 'Failed to sign in',timeout: 5000,icon:'fa fa-times-circle'})  
      }
      })

    },

    /**
     * Handle Authentication Callback action
     */
    handleAuthCallback: function({commit}) {
      AuthService.handleAuthentication(function(err, payload){
        if(err){
          commit(LOGIN_FAILURE)
        }
        else{
          commit(LOGIN_SUCCESS)
          commit(SET_ACCOUNT, payload)
          //Notification.notify({type: 'success', message: 'Successfuly signed in',timeout: 5000,icon: "ti-check"})
        }
      })
    },
    
    /**
     * 
     */
    logout: function({commit}) {
      AuthService.logout()
      commit(LOGOUT)
    },
    /**
     * 
     */
    initAuthState: function({commit}) {
      //get Account 
  
      //get AppUser
      
      //commit
      commit(INIT_AUTH_STATE, AuthService.getAccount())
    },

    // load profile data

    loadProfile: async function({commit}){
    try{
      let profile=await Api.getProfile();
      commit(GET_PROFILE_BY_CONTRACTOR_SUCCESS,profile)
    }
    
    catch(e)
    {
      commit(GET_PROFILE_BY_CONTRACTOR_FAILURE)
    }
    },
    // update profile data
    
    updateProfile: async function({commit,state}){
      let myProfile=state.profile
    try{
      let profileData=await Api.setProfile(myProfile);
      commit(SET_PROFILE_BY_CONTRACTOR_SUCCESS,profileData)
      Notification.notify({
        title: 'Votre profil a été mis à jour avec succès!',
        messsage: 'Very nicely done',
        type:'success',
        timeout: 5000,
        icon: "ti-check",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        position:'top-right'
        })
    }
    
    catch(e)
    {
      commit(SET_PROFILE_BY_CONTRACTOR_FAILURE)
      Notification.notify({
        title: "La modification du profil a échoué",
        timeout: 5000,
        type:'danger',
        icon:'fa fa-times-circle',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        position:'top right'
      })
    }
    },
    updatePassword: async function({commit,state},password){
    try{
      let updatedPassword=await Api.updatePassword(password);
      commit(UPDAYE_PW_SUCCESS,updatedPassword)
      Notification.notify({
        title: 'Votre mot de passe a été mis à jour avec succès!',
        messsage: 'Very nicely done',
        type:'success',
        timeout: 5000,
        icon: "ti-check",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        position:'top-right'
        })
    }
    
    catch(e)
    {
      commit(UPDAYE_PW_ERROR)
      Notification.notify({
        title: "La modification du mot de passe a échoué",
        timeout: 5000,
        type:'danger',
        icon:'fa fa-times-circle',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        position:'top right'
      })
    }
    }
  }
  