<template>
    <div class="col-12">
        <el-button v-if="!filterDisplayed" round v-on:click="displayFilter()">Filtrer</el-button>
        <card v-if="filterDisplayed" class="filter">
        <div class="row">
        <div class="col-md-3 col-12">
            <el-select v-model="priorityFilter" placeholder="Priorité" @change="filterList()">
                <el-option :value="empty">Tous</el-option>
                <el-option
                v-for="item in priorities"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                >
                </el-option>
            </el-select>
        </div>
        <div class="col-md-3 col-12">
            <el-select v-model="categoryFilter" placeholder="Catégorie" @change="filterList()">
                <el-option :value="empty">Tous</el-option>
                <el-option
                v-for="item in categories"
                :key="item.name"
                :label="item.name"
                :value="item.name">
                </el-option>
            </el-select>
        </div>
        <div class="col-md-3 col-12">
            <el-select v-model="assigneeFilter" placeholder="Intervenant" @change="filterList()">
                <el-option :value="empty">Tous</el-option>
                <el-option
                v-for="item in assignees"
                :key="item.id"
                :label="item.name"
                :value="item.name">
                </el-option>
            </el-select>
        </div>
        <div class="col-md-2 col-6">
            <el-button round v-on:click="reset()">Rénitisaliser</el-button>
        </div>
        <div class="col-md-1 col-6">
            <el-button circle icon="el-icon-close" v-on:click="hideFilter()"></el-button>
        </div>
        </div>
    </card>
    </div>  
</template>
<script>
import config from '@/api/config.js'
import EventBus from '@/eventBus'
    
    export default {
    name: 'operations-filter',
    data: function() {
            return {
                priorityFilter: '',
                categoryFilter: '',
                assigneeFilter: '',
                filterDisplayed: false,
                empty:''
        }
    },
    computed:{ 
        statuses(){
            return config.Status
        },
        categories(){
            return config.Categories
        },
        assignees() {
            return this.$store.getters['contractor/getTeamMembers'];
        },
        priorities(){
            return config.Priorities
        },
    },
    methods:{
        filterList(){
        let options = {
            priority:this.priorityFilter,
            category:this.categoryFilter,
            firstName:this.assigneeFilter.split(" ")[0],
            lastName:this.assigneeFilter.split(" ")[1]
        }   
        this.$store.dispatch('contractor/loadEvents',options);   
        EventBus.$emit('ADD_FILTER_CALENDAR', options);
        },
        reset(){
            this.assigneeFilter=''
            this.categoryFilter=''
            this.priorityFilter=''
            let options = {
            priority:this.priorityFilter,
            category:this.categoryFilter,
            firstName:this.assigneeFilter,
            lastName:this.assigneeFilter
            }   
        this.$store.dispatch('contractor/loadEvents',options);
        EventBus.$emit('ADD_FILTER_CALENDAR', options);       
        },
        hideFilter(){
            this.filterDisplayed=false;
        },
        displayFilter(){
            this.filterDisplayed=true
        }
    },
    mounted(){
        //this.filterList()
        //this.reset();
    }
}
</script>
<style scoped>
@media screen and (max-width: 999px) {
.row .col-12{
    margin-bottom: 10px;
}  
}
</style>
    